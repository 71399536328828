import React from "react";
import styled from "@emotion/styled";
import { useCardContext } from "../../../context/CardContext";
import { FONT_STYLE } from "../../../utils/Styles";
const Item = styled.div `
  ${FONT_STYLE.SEMIBOLD}
  text-decoration: underline;
  margin-top: 8px;
  cursor: pointer;
`;
function DictionaryItem({ id, title }) {
    const { setCardId } = useCardContext();
    return (React.createElement(Item, { onClick: () => {
            setCardId(id);
        } }, title));
}
export default DictionaryItem;
