import { createTheme, ThemeProvider } from "@mui/material/styles";
import React from "react";
import { CardContextProvider } from "../context/CardContextProvider";
import Card from "./Card";
import { MuiTheme } from "./MuiTheme";
import { GalleryContextProvider } from "../context/GalleryContextProvider";
import Gallery from "./Gallery";
import Help from "./Help/Help";
import { HelpContextProvider } from "../context/HelpContextProvider";
import { isMobileOnly } from "react-device-detect";
import { MobileInfo } from "./MobileInfo/MobileInfo";
import { TabletPortraitOverlay } from "./TabletPortraitOverlay/TabletPortraitOverlay";
const theme = createTheme(MuiTheme);
const AppContainer = ({ children }) => {
    if (isMobileOnly) {
        return React.createElement(MobileInfo, null);
    }
    return (React.createElement(ThemeProvider, { theme: theme },
        React.createElement(HelpContextProvider, null,
            React.createElement(GalleryContextProvider, null,
                React.createElement(CardContextProvider, null,
                    children,
                    React.createElement(Card, null),
                    React.createElement(Gallery, null),
                    React.createElement(Help, null),
                    React.createElement(TabletPortraitOverlay, null))))));
};
export { AppContainer };
