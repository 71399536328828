import { initializeApp } from "firebase/app";
export const Firebase = {
    init: () => {
        initializeApp({
            apiKey: "AIzaSyCLipF6MWiaronrb9bFUPw3hmhn1HBwq3M",
            authDomain: "interactive-sucho.firebaseapp.com",
            databaseURL: "https://interactive-sucho.firebaseio.com",
            storageBucket: "interactive-sucho.appspot.com",
            projectId: "interactive-sucho",
            messagingSenderId: "196490048976",
            appId: "1:196490048976:web:f411e98c52e78c616bb5d5",
        });
    },
};
