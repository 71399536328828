import { IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import { Howler } from "howler";
const STORAGE_ID = "sucho-sound";
const SoundSwitch = () => {
    const [soundOn, setSoundOn] = useState(true);
    useEffect(() => {
        const sound = localStorage.getItem(STORAGE_ID);
        if (sound === "0") {
            setSoundOn(false);
        }
    }, []);
    useEffect(() => {
        localStorage.setItem(STORAGE_ID, soundOn ? "1" : "0");
        Howler.mute(!soundOn);
    }, [soundOn]);
    return (React.createElement(IconButton, { title: "Zvuk", size: "small", color: "primary", onFocus: (e) => {
            e.target.blur();
        }, onClick: () => {
            setSoundOn(!soundOn);
        } }, soundOn ? React.createElement(VolumeUpIcon, null) : React.createElement(VolumeOffIcon, null)));
};
export { SoundSwitch };
