import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { getFirestore, getDocs, collection, query, where, } from "firebase/firestore";
import DictionaryItem from "./DictionaryItem";
import { CARDS_COLLECTION } from "../../../const";
import { fadeInAnimation } from "../../../utils/Styles";
const DictionaryContainer = styled.div `
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  ${fadeInAnimation}
`;
const DictionaryColumn = styled.div `
  width: 48%;
  margin-right: 1%;
`;
function Dictionary() {
    const [items, setItems] = useState([]);
    useEffect(() => {
        const q = query(collection(getFirestore(), CARDS_COLLECTION), where("dictionary", "==", true));
        getDocs(q)
            .then((data) => {
            if (data.docs) {
                var tempItems = [];
                data.docs.forEach((doc) => {
                    const data = doc.data();
                    tempItems.push({
                        id: doc.id,
                        title: data.title,
                    });
                });
                // Sort by title
                tempItems.sort((a, b) => a.title.localeCompare(b.title));
                setItems(tempItems);
            }
        })
            .catch((error) => {
            console.warn("Error getting document:", error);
        });
    }, []);
    if (items.length === 0) {
        return null;
    }
    const halfCount = Math.ceil(items.length / 2);
    const firstHalf = items.slice(0, halfCount);
    const secondHalf = items.slice(halfCount);
    return (React.createElement(DictionaryContainer, null,
        React.createElement(DictionaryColumn, null, firstHalf.map((item, i) => {
            return (React.createElement(DictionaryItem, { key: item.id, id: item.id, title: item.title }));
        })),
        React.createElement(DictionaryColumn, null, secondHalf.map((item, i) => {
            return (React.createElement(DictionaryItem, { key: item.id, id: item.id, title: item.title }));
        }))));
}
export default Dictionary;
