import React from "react";
import styled from "@emotion/styled";
const Wrapper = styled.div `
  z-index: 1000;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: #faf7e4;
`;
const Message = styled.div `
  margin: 40px;
  text-align: center;

  h3 {
    margin-top: 16px;
  }
`;
export const MobileInfo = () => {
    return (React.createElement(Wrapper, null,
        React.createElement(Message, null,
            React.createElement("h2", null, "Litujeme, ale aplikace nen\u00ED dostupn\u00E1 pro mobiln\u00ED za\u0159\u00EDzen\u00ED."),
            React.createElement("h3", null, "Pou\u017Eijte po\u010D\u00EDta\u010D."))));
};
