import React, { Fragment, useState } from "react";
import { Modal, Paper, Button } from "@mui/material";
import styled from "@emotion/styled";
import Urls from "./Urls/Urls";
import Dictionary from "./Dictionary/Dictionary";
import Download from "./Download/Download";
import Metodiky from "./Metodiky/Metodiky";
import { moveInAnimation } from "../../utils/Styles";
import { HorizontalSpacer } from "../HorizontalSpacer";
import { CloseButton } from "../CloseButton";
import Zdroje from "./Zdroje/Zdroje";
const DatabaseModal = styled(Modal) `
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const ContentWrap = styled.div `
  position: relative;
  height: 80%;
  width: 90%;
  max-width: 800px;
  margin: 40px;
  display: flex;
  ${moveInAnimation}
`;
const ModalCloseButton = styled(CloseButton) `
  position: absolute;
  z-index: 1;
  top: 16px;
  right: -40px;
`;
const DatabaseContent = styled(Paper) `
  position: relative;
  padding: 28px;
  display: flex;
  flex: 1;
  flex-direction: column;
`;
const Title = styled.h2 `
  margin: 0 0 16px;
`;
const Menu = styled.div `
  display: flex;
  margin-bottom: 8px;
`;
const MenuButton = styled(Button) `
  height: 36px;
`;
const DatabaseSection = styled.div `
  overflow-y: scroll;
`;
const MENU_ITEMS = [
    { label: "Slovník", id: 0 },
    { label: "Zdroje k tématu", id: 1 },
    { label: "Externí zdroje", id: 2 },
    { label: "Ke stažení", id: 3 },
    { label: "Pracovní listy", id: 4 },
];
function Database({ open, onClose }) {
    const [value, setValue] = useState(0);
    return (React.createElement(DatabaseModal, { open: open, onClose: onClose },
        React.createElement(ContentWrap, null,
            React.createElement(ModalCloseButton, { onClick: onClose }),
            React.createElement(DatabaseContent, { elevation: 6 },
                React.createElement(Title, null, "Datab\u00E1ze"),
                React.createElement(Menu, null, MENU_ITEMS.map((item, index) => (React.createElement(Fragment, { key: item.id },
                    React.createElement(MenuButton, { variant: value === item.id ? "outlined" : "contained", color: "primary", disableElevation: true, onClick: () => {
                            setValue(item.id);
                        } }, item.label),
                    index !== MENU_ITEMS.length - 1 && (React.createElement(HorizontalSpacer, { width: 6 })))))),
                React.createElement(DatabaseSection, null,
                    value === 0 && React.createElement(Dictionary, null),
                    value === 1 && React.createElement(Urls, null),
                    value === 2 && React.createElement(Zdroje, null),
                    value === 3 && React.createElement(Download, null),
                    value === 4 && React.createElement(Metodiky, null))))));
}
export default Database;
