import {
  MainMenu,
  HorizontalSpacer,
  PAGE_URL,
  useCardContext,
  HelpModalState,
  useHelpContext,
  FutureInfo,
  FutureInfoData,
  FutureMenu,
} from "@sucho/shared";
import { useEffect, useState } from "react";
import { Button } from "@mui/material";
import { MapMenu } from "./MapMenu";

function App() {
  const cardContext = useCardContext();
  const { setHelpModalState } = useHelpContext();
  const [showFutureMenu, setShowFutureMenu] = useState(false);
  const [futureInfoData, setFutureInfoData] = useState<FutureInfoData | null>(
    null
  );

  const handleFutureMenuClose = () => {
    setShowFutureMenu(false);
  };

  const handleFutureInfoClose = () => {
    setFutureInfoData(null);
  };

  useEffect(() => {
    const handleOpenCard = (cardId: string | null) => {
      cardContext.setCardId(cardId);
    };
    window.map.signals.openCard.add(handleOpenCard);
    return () => {
      window.map.signals.openCard.remove(handleOpenCard);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Open welcome modal on first load
    setHelpModalState(HelpModalState.WELCOME);
  }, [setHelpModalState]);

  return (
    <>
      <MainMenu helpState={HelpModalState.MAP}>
        <Button
          size="small"
          variant="contained"
          color="primary"
          disableElevation
          href={PAGE_URL.STORY}
        >
          <img height={18} src="./img/menu-story-icon.png" alt="Příběh" />
          <HorizontalSpacer width={6} />
          Příběh
        </Button>
        <HorizontalSpacer width={6} />
        <Button
          size="small"
          variant="contained"
          color="primary"
          disableElevation
          onClick={() => setShowFutureMenu(true)}
        >
          Budoucnost
        </Button>
      </MainMenu>
      <MapMenu />
      <FutureMenu
        open={showFutureMenu}
        onClose={handleFutureMenuClose}
        onSelect={setFutureInfoData}
      />
      <FutureInfo onClose={handleFutureInfoClose} data={futureInfoData} />
    </>
  );
}

export default App;
