export var HOTSPOT_ID;
(function (HOTSPOT_ID) {
    HOTSPOT_ID["NOVE_MLYNY"] = "nove-mlyny";
    HOTSPOT_ID["SAKVICE"] = "sakvice";
    HOTSPOT_ID["SARDICE"] = "sardice";
    HOTSPOT_ID["DOMANIN"] = "domanin";
    HOTSPOT_ID["SYROVIN"] = "syrovin";
    HOTSPOT_ID["DUNAJOVICE"] = "dunajovice";
})(HOTSPOT_ID || (HOTSPOT_ID = {}));
var HOTSPOT_MAP;
(function (HOTSPOT_MAP) {
    HOTSPOT_MAP["SAKVICE"] = "sakvice";
    HOTSPOT_MAP["SARDICE"] = "sardice";
    HOTSPOT_MAP["DUNAJOVICE"] = "dunajovice";
    HOTSPOT_MAP["DOMANIN_SYROVIN"] = "domanin-syrovin";
})(HOTSPOT_MAP || (HOTSPOT_MAP = {}));
export const HOTSPOTS = [
    {
        id: HOTSPOT_ID.SAKVICE,
        detailId: 1,
        mapId: HOTSPOT_MAP.SAKVICE,
        properties: {
            name: "Šakvice",
        },
        pos: [16.7142389, 48.8974589],
        detailCenterPos: [16.7142389, 48.8974589],
        boundary: { LAT: [48.86543, 48.91083], LON: [16.69647, 16.75059] },
    },
    {
        id: HOTSPOT_ID.SARDICE,
        detailId: 2,
        mapId: HOTSPOT_MAP.SARDICE,
        properties: {
            name: "Šardice",
        },
        pos: [17.0281153, 48.9640286],
        detailCenterPos: [17.0281153, 48.9640286],
        boundary: { LAT: [48.94242, 48.99403], LON: [16.99173, 17.05824] },
    },
    {
        id: HOTSPOT_ID.DOMANIN,
        detailId: 3,
        mapId: HOTSPOT_MAP.DOMANIN_SYROVIN,
        properties: {
            name: "Domanín",
        },
        pos: [17.2847611, 49.0016711],
        detailCenterPos: [17.2847611, 49.0016711],
        boundary: { LAT: [48.98644, 49.03464], LON: [17.24264, 17.31345] },
    },
    {
        id: HOTSPOT_ID.DUNAJOVICE,
        detailId: 5,
        mapId: HOTSPOT_MAP.DUNAJOVICE,
        properties: {
            name: "Dolní Dunajovice",
        },
        pos: [16.5928297, 48.8544711],
        detailCenterPos: [16.5928297, 48.8544711],
        boundary: { LAT: [48.8215, 48.899], LON: [16.55014, 16.60125] },
    },
    {
        id: HOTSPOT_ID.SYROVIN,
        detailId: 4,
        mapId: HOTSPOT_MAP.DOMANIN_SYROVIN,
        properties: {
            name: "Syrovín - Těmice",
        },
        pos: [17.2639311, 49.0258231],
        detailCenterPos: [17.2639311, 49.0258231],
        boundary: { LAT: [48.98644, 49.03464], LON: [17.24264, 17.31345] },
    },
    {
        id: HOTSPOT_ID.NOVE_MLYNY,
        detailId: 0,
        mapId: HOTSPOT_MAP.SAKVICE,
        properties: {
            name: "Nové Mlýny",
        },
        pos: [16.6, 48.8959706],
        detailCenterPos: [16.72429, 48.883978],
        boundary: { LAT: [48.86543, 48.91083], LON: [16.69647, 16.75059] },
    },
];
