import { useCallback, useEffect, useState } from "react";
import {
  FormGroup,
  FormControlLabel,
  Checkbox,
  Slider,
  Button,
  Paper,
} from "@mui/material";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import styled from "@emotion/styled";
import {
  Colors,
  VerticalSpacer,
  MapUtils,
  moveInLeftAnimation,
  MoreButton,
  CloseButton,
} from "@sucho/shared";

const ZOOM_STEP = 0.5;

const MenuOpenButton = styled(MoreButton)`
  position: absolute;
  bottom: 300px;
  left: 0;
  ${moveInLeftAnimation}
`;
const MapMenuWrap = styled.div`
  position: absolute;
  bottom: 8px;
  left: 8px;
  ${moveInLeftAnimation}
`;
const MenuCloseButton = styled(CloseButton)`
  position: absolute;
  z-index: 1;
  top: 16px;
  right: -40px;
`;
const MapMenuContainer = styled(Paper)`
  pointer-events: all;
  padding: 24px;
`;
const SliderLabels = styled.div`
  display: flex;
  justify-content: space-between;
`;

const HeatSwitch = styled(FormControlLabel)`
  margin-bottom: 12px;
`;

const MapMenu = () => {
  const [isOpen, setOpen] = useState(true);
  const [activeLayers, setActiveLayers] = useState(MapUtils.globalLayerIds);
  const [showHeat, setShowHeat] = useState(false);

  const handleOpacityUpdate = useCallback(
    (_: unknown, value: number | number[]) => {
      if (typeof value === "number") {
        window.map.signals.opacityUpdate.dispatch(value / 100);
      }
    },
    []
  );

  const handleCheckChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, value } = event.target;
    const numValue = Number(value);
    if (checked) {
      setActiveLayers([...activeLayers, numValue]);
    } else {
      setActiveLayers(
        activeLayers.filter((_, i) => i !== activeLayers.indexOf(numValue))
      );
    }
  };

  useEffect(() => {
    window.map.signals.activeLayersUpdate.dispatch(activeLayers);
  }, [activeLayers]);

  useEffect(() => {
    window.map.signals.heatSwitchUpdate.dispatch(showHeat);
  }, [showHeat]);

  if (!isOpen) {
    return (
      <MenuOpenButton
        onClick={() => {
          setOpen(true);
        }}
      />
    );
  }

  return (
    <MapMenuWrap>
      <MenuCloseButton
        onClick={() => {
          setOpen(false);
        }}
      />
      <MapMenuContainer elevation={6}>
        <h3>Zoom</h3>
        <VerticalSpacer height={12} />
        <Button
          size="small"
          color="primary"
          style={{ marginRight: 4 }}
          variant="contained"
          onClick={() => {
            window.map.signals.zoomUpdate.dispatch(-ZOOM_STEP);
          }}
        >
          <ZoomOutIcon />
        </Button>
        <Button
          size="small"
          color="primary"
          variant="contained"
          onClick={() => {
            window.map.signals.zoomUpdate.dispatch(ZOOM_STEP);
          }}
        >
          <ZoomInIcon />
        </Button>
        <VerticalSpacer height={16} />
        <h3>Čas</h3>
        <VerticalSpacer height={12} />
        <Slider onChange={handleOpacityUpdate} defaultValue={100} step={1} />
        <SliderLabels>
          <h4>1950</h4>
          <h4>2021</h4>
        </SliderLabels>
        <VerticalSpacer height={16} />
        <h3>Legenda</h3>
        <VerticalSpacer height={12} />
        <HeatSwitch
          control={
            <Checkbox
              checked={showHeat}
              onChange={() => setShowHeat(!showHeat)}
            />
          }
          label="Klima"
        />
        <FormGroup>
          {MapUtils.globalLayerIds.map((layerId) => {
            const { name } = MapUtils.getLayerData(layerId);
            return (
              <FormControlLabel
                key={layerId}
                control={
                  <Checkbox
                    value={layerId}
                    checked={activeLayers.includes(layerId)}
                    style={{
                      color: Colors.getMapFillHexColor(layerId),
                    }}
                    onChange={handleCheckChange}
                  />
                }
                label={name}
              />
            );
          })}
        </FormGroup>
      </MapMenuContainer>
    </MapMenuWrap>
  );
};

export { MapMenu };
