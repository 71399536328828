import React from "react";
import { Paper, Modal, Button } from "@mui/material";
import styled from "@emotion/styled";
import { moveInAnimation } from "../../utils/Styles";
import { CloseButton } from "../CloseButton";
const MENU_DATA = [
    {
        title: "Změna klimatu",
        id: "f0",
    },
    {
        title: "Energetické zdroje",
        id: "f1",
    },
    {
        title: "Zemědělství",
        id: "f2",
    },
    {
        title: "Vodní zdroje",
        id: "f3",
    },
    {
        title: "Lidská sídla",
        id: "f4",
    },
    {
        title: "Ekosystém",
        id: "f5",
    },
];
const MainModal = styled(Modal) `
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const ContentWrap = styled.div `
  position: relative;
  ${moveInAnimation}
`;
const ModalCloseButton = styled(CloseButton) `
  position: absolute;
  z-index: 1;
  top: 16px;
  right: -40px;
`;
const ContentPaper = styled(Paper) `
  padding: 20px;
  display: flex;
  flex-direction: column;
`;
const FutureButton = styled(Button) `
  margin-top: 8px;
  padding: 12px 40px;
`;
export const FutureMenu = ({ open, onSelect, onClose }) => {
    return (React.createElement(MainModal, { open: open, onClose: onClose },
        React.createElement(ContentWrap, null,
            React.createElement(ModalCloseButton, { onClick: onClose }),
            React.createElement(ContentPaper, null,
                React.createElement("h1", null, "Budoucnost"),
                MENU_DATA.map((data) => (React.createElement(FutureButton, { key: data.id, color: "primary", variant: "contained", size: "large", onClick: () => onSelect(data) }, data.title)))))));
};
