import { FlyToInterpolator } from "@deck.gl/core/typed";
const ZOOM_LIMITS = {
    MIN: 10,
    MAX: 16,
};
class MapViewState {
    saveState;
    stateId;
    transitionInterpolator;
    viewState;
    constructor(saveState, stateId, defaultZoom, defaultLatitude, defaultLongitude) {
        this.saveState = saveState;
        this.stateId = stateId;
        const { latitude, longitude, zoom } = this.getSavedState(defaultZoom, defaultLatitude, defaultLongitude);
        this.transitionInterpolator = new FlyToInterpolator();
        this.viewState = {
            latitude,
            longitude,
            zoom,
            maxZoom: ZOOM_LIMITS.MAX,
            minZoom: ZOOM_LIMITS.MIN,
            pitch: 30,
            bearing: 0,
            transitionDuration: 100,
            transitionInterpolator: this.transitionInterpolator,
        };
    }
    getCurrentState() {
        return { ...this.viewState };
    }
    updateState(viewState) {
        const { longitude, latitude, zoom } = viewState;
        // Reference update
        this.viewState.longitude = longitude;
        this.viewState.latitude = latitude;
        this.viewState.zoom = zoom;
        // Save
        this.saveViewState();
    }
    updateZoom(value) {
        const newZoom = Math.min(Math.max(this.viewState.zoom + value, ZOOM_LIMITS.MIN), ZOOM_LIMITS.MAX);
        this.viewState.zoom = newZoom;
        return { ...this.viewState };
    }
    saveViewState() {
        if (!this.saveState) {
            return;
        }
        const { longitude, latitude, zoom } = this.viewState;
        localStorage.setItem(this.stateId, JSON.stringify({ longitude, latitude, zoom }));
    }
    getSavedState(defaultZoom, defaultLatitude, defaultLongitude) {
        const savedState = localStorage.getItem(this.stateId);
        if (this.saveState && savedState) {
            const { latitude, longitude, zoom } = JSON.parse(savedState);
            return {
                latitude: latitude ?? defaultLatitude,
                longitude: longitude ?? defaultLongitude,
                zoom: zoom ?? defaultZoom,
            };
        }
        return {
            latitude: defaultLatitude,
            longitude: defaultLongitude,
            zoom: defaultZoom,
        };
    }
}
export { MapViewState };
