import React from "react";
import { CARD_LINK_PREFIX } from "../../../const";
import SlateCardLink from "./components/SlateCardLink";
import SlateImage from "./components/SlateImage";
import SlateVideo from "./components/SlateVideo";
const SlateElement = (props) => {
    const { attributes, children, element } = props;
    switch (element.type) {
        case "heading-one":
            return React.createElement("h1", { ...attributes }, children);
        case "heading-two":
            return React.createElement("h2", { ...attributes }, children);
        case "block-quote":
            return React.createElement("blockquote", { ...attributes }, children);
        case "bulleted-list":
            return React.createElement("ul", { ...attributes }, children);
        case "numbered-list":
            return React.createElement("ol", { ...attributes }, children);
        case "list-item":
            return React.createElement("li", { ...attributes }, children);
        case "link":
            if (element.url.indexOf(CARD_LINK_PREFIX) === 0) {
                return React.createElement(SlateCardLink, { ...props });
            }
            return (React.createElement("a", { ...attributes, href: element.url, target: "_blank", rel: "noreferrer" }, children));
        case "image":
            return React.createElement(SlateImage, { ...props });
        case "video":
            return React.createElement(SlateVideo, { ...props });
        case "line-horizontal":
            return (React.createElement("div", { ...attributes },
                React.createElement("hr", null),
                children));
        // Table
        case "table":
            return (React.createElement("table", null,
                React.createElement("tbody", { ...attributes }, children)));
        case "table-row":
            return React.createElement("tr", { ...attributes }, children);
        case "table-cell":
            return React.createElement("td", { ...attributes }, children);
        default:
            return React.createElement("p", { ...attributes }, children);
    }
};
export default SlateElement;
