import { Deck, AmbientLight, LightingEffect } from "@deck.gl/core/typed";
import { GeoJsonLayer, IconLayer, TextLayer } from "@deck.gl/layers/typed";
import { TileLayer } from "@deck.gl/geo-layers/typed";
import {
  Colors,
  getMapSignals,
  HOTSPOTS,
  MapSignals,
  MapUtils,
  MapViewState,
  SIMPLE_SPOTS,
  DEFAULT_LAYER_COLOR,
  PAGE_URL,
} from "@sucho/shared";

const FONT_FAMILY = "mapFont";
const BOUNDARIES = { LAT: [48.616621, 49.215137], LON: [16.205181, 17.658148] };

export default class Map {
  signals: MapSignals;
  activeLayers = [...MapUtils.globalLayerIds];

  deck?: Deck;
  presentOpacity = 1;
  heatVisible = false;
  townsVisible = false;
  mapViewState: MapViewState;

  constructor() {
    this.mapViewState = new MapViewState(true, "global", 12, 48.91, 16.64);

    // Save reference
    window.map = this;

    // Init variables
    this.signals = getMapSignals();

    // Handlers
    this.signals.zoomUpdate.add(this.handleZoomUpdate, this);
    this.signals.opacityUpdate.add(this.handleOpacityUpdate, this);
    this.signals.activeLayersUpdate.add(this.handleActiveLayersUpdate, this);
    this.signals.heatSwitchUpdate.add(this.handleHeatSwitchUpdate, this);

    // Map
    this.mapSetup();
  }

  async mapSetup() {
    // Font
    const font = new FontFace(FONT_FAMILY, "url(./font/map-font.ttf)");
    await font.load();
    document.fonts.add(font);
    // Map
    const ambientLight = new AmbientLight({
      color: [255, 255, 255],
      intensity: 3.0,
    });
    const lightingEffect = new LightingEffect({ ambientLight });
    this.deck = new Deck({
      initialViewState: this.mapViewState.getCurrentState(),
      controller: true,
      useDevicePixels: false, // NOTE: Off, for performance on retina displays
      effects: [lightingEffect],
      layers: this.getLayers(),
      onViewStateChange: this.handleViewStateChange.bind(this),
    });
  }

  handleViewStateChange({ viewState }: Record<string, any>) {
    viewState.longitude = Math.min(
      BOUNDARIES.LON[1],
      Math.max(BOUNDARIES.LON[0], viewState.longitude)
    );
    viewState.latitude = Math.min(
      BOUNDARIES.LAT[1],
      Math.max(BOUNDARIES.LAT[0], viewState.latitude)
    );
    this.mapViewState.updateState(viewState);

    // Try to switch towns
    if (viewState.zoom > 11) {
      if (!this.townsVisible) {
        this.townsVisible = true;
        this.deck?.setProps({ layers: this.getLayers() });
      }
    } else {
      if (this.townsVisible) {
        this.townsVisible = false;
        this.deck?.setProps({ layers: this.getLayers() });
      }
    }

    return viewState;
  }

  handleZoomUpdate(value: number) {
    this.deck?.setProps({
      initialViewState: this.mapViewState.updateZoom(value),
    });
  }

  handleOpacityUpdate(value: number) {
    if (this.presentOpacity === value) {
      return;
    }
    this.presentOpacity = value;
    this.deck?.setProps({ layers: this.getLayers() });
  }

  handleActiveLayersUpdate(activeLayers: number[]) {
    this.activeLayers = activeLayers;
    this.deck?.setProps({ layers: this.getLayers() });
  }

  handleHeatSwitchUpdate(showHeat: boolean) {
    this.heatVisible = showHeat;
    this.deck?.setProps({ layers: this.getLayers() });
  }

  getLayers(): (GeoJsonLayer | IconLayer | TileLayer | TextLayer)[] {
    return [
      MapUtils.getTileLayer(),
      new GeoJsonLayer({
        id: "pastLayer",
        data: `./map/past.json`,
        extruded: false,
        stroked: false,
        opacity: this.presentOpacity === 1 ? 0 : 1,
        getFillColor: (f: any) => {
          const { id } = f.properties;
          if (this.activeLayers.includes(id)) {
            return Colors.getMapFillColor(id);
          }
          return DEFAULT_LAYER_COLOR;
        },
        updateTriggers: {
          getFillColor: this.activeLayers,
          opacity: this.presentOpacity,
        },
      }),
      new GeoJsonLayer({
        id: "railsPast",
        data: "./map/rails-past.json",
        opacity: this.presentOpacity === 1 ? 0 : 1,
        getLineColor: () => [169, 98, 36],
        getLineWidth: 50,
        updateTriggers: {
          opacity: this.presentOpacity,
        },
      }),
      new GeoJsonLayer({
        id: "roadsPast",
        data: "./map/roads-past.json",
        opacity: this.presentOpacity === 1 ? 0 : 1,
        getLineColor: () => [41, 42, 46],
        getLineWidth: 50,
        updateTriggers: {
          opacity: this.presentOpacity,
        },
      }),
      new GeoJsonLayer({
        id: "presentLayer",
        data: "./map/present.json",
        extruded: true,
        getElevation: 10,
        stroked: false,
        opacity: this.presentOpacity,
        getFillColor: (f: any) => {
          const { id } = f.properties;
          if (this.activeLayers.includes(id)) {
            return Colors.getMapFillColor(id);
          }
          return DEFAULT_LAYER_COLOR;
        },
        updateTriggers: {
          getFillColor: this.activeLayers,
          opacity: this.presentOpacity,
        },
      }),
      new GeoJsonLayer({
        id: "railsPresent",
        data: "./map/rails-present.json",
        parameters: {
          depthTest: false,
        },
        opacity: this.presentOpacity,
        getLineColor: () => [169, 98, 36],
        getLineWidth: 50,
        updateTriggers: {
          opacity: this.presentOpacity,
        },
      }),
      new GeoJsonLayer({
        id: "roadsPresent",
        data: "./map/roads-present.json",
        parameters: {
          depthTest: false,
        },
        opacity: this.presentOpacity,
        getLineColor: () => [41, 42, 46],
        getLineWidth: 50,
        updateTriggers: {
          opacity: this.presentOpacity,
        },
      }),
      new GeoJsonLayer({
        id: "heatLayer",
        data: "./map/heat.json",
        visible: this.heatVisible,
        opacity: 0.7,
        stroked: false,
        parameters: {
          depthTest: false,
        },
        getFillColor: (f: any) => {
          return f.properties.c;
        },
        updateTriggers: {
          visible: this.heatVisible,
        },
      }),
      new TextLayer({
        id: "townNames",
        visible: this.townsVisible,
        data: "./map/towns.json",
        sizeUnits: "pixels",
        getSize: () => 15,
        maxWidth: 700,
        fontFamily: FONT_FAMILY,
        characterSet: "auto", // TODO: Consider setting to final charset
        getPosition: (d) => d.p,
        getText: (d) => d.n,
        getColor: [0, 0, 0],
        background: true,
        getBackgroundColor: [255, 255, 255],
        backgroundPadding: [15, 10],
        getBorderWidth: 2,
        getBorderColor: [0, 0, 0],
        parameters: {
          depthTest: false,
        },
        updateTriggers: {
          visible: this.townsVisible,
        },
      }),
      new TextLayer({
        id: "simpleSpotLabels",
        data: SIMPLE_SPOTS,
        sizeUnits: "pixels",
        getSize: () => 15,
        maxWidth: 700,
        fontFamily: FONT_FAMILY,
        characterSet: "auto", // TODO: Consider setting to final charset
        getPosition: (d) => d.pos,
        getText: (d) => d.properties.name,
        getColor: [255, 255, 255],
        background: true,
        getBackgroundColor: [107, 78, 11],
        backgroundPadding: [15, 10],
        getBorderWidth: 2,
        getBorderColor: [255, 255, 255],
        getTextAnchor: "middle",
        getAlignmentBaseline: "bottom",
        parameters: {
          depthTest: false,
        },
        pickable: true,
        onClick: (d) => {
          this.signals.openCard.dispatch(d.object.card);
        },
      }),
      new IconLayer({
        id: "hotspotLabels",
        data: HOTSPOTS,
        iconAtlas: "./img/map-labels.png",
        iconMapping: "./img/map-labels.json",
        getIcon: (d) => `h-${d.detailId}`,
        sizeScale: 40,
        getPosition: (d) => d.pos,
        parameters: {
          depthTest: false,
        },
        pickable: true,
        onClick: (d) => {
          window.location.href = `${PAGE_URL.DETAIL}?mapa=${d.object.id}`;
        },
      }),
    ];
  }
}
