import React from "react";
import styled from '@emotion/styled';
import { CARD_LINK_PREFIX } from "../../../../const";
import { useCardContext } from "../../../../context/CardContext";
const LinkSpan = styled.span `
  background-color: #ffed1b;
  color: #e40ba0;
  font-weight: bold;
  cursor: pointer;
`;
const SlateCardLink = ({ attributes, children, element }) => {
    const { setCardId } = useCardContext();
    return (React.createElement(LinkSpan, { ...attributes, onClick: () => {
            setCardId(element.url.replace(CARD_LINK_PREFIX, ""));
        } }, children));
};
export default SlateCardLink;
