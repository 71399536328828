import React from "react";
import { Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import styled from "@emotion/styled";
const StyledButton = styled(Button) `
  pointer-events: all;
  z-index: -1;
  padding: 0;
  width: 40px;
  height: 40px;
  border-radius: 0;
  box-shadow: 4px 6px 10px rgb(0 0 0 / 70%);

  :hover {
    box-shadow: 4px 6px 10px rgb(0 0 0 / 70%);
  }
`;
const CloseButton = (props) => {
    return (React.createElement(StyledButton, { variant: "contained", ...props },
        React.createElement(CloseIcon, { fontSize: "large" })));
};
export { CloseButton };
