import { BitmapLayer } from "@deck.gl/layers/typed";
import { TileLayer } from "@deck.gl/geo-layers/typed";
const LAYER_IDS = [0, 1, 2, 3, 4, 5, 6, 7, 8];
const DETAIL_LAYER_GROUPS = [
    { id: 0, name: "Lesy", ids: [107, 123, 126] },
    { id: 1, name: "Nemovitosti", ids: [102, 124, 119, 115, 103, 101, 118, 106] },
    {
        id: 2,
        name: "Pole a vinice",
        ids: [128, 111, 114, 112, 127, 110, 109, 105, 125],
    },
    { id: 3, name: "Zeleň", ids: [113, 104, 108, 116] },
    { id: 4, name: "Voda", ids: [117, 121, 122, 120] },
];
const DETAIL_LAYER_IDS = DETAIL_LAYER_GROUPS.map((g) => g.ids).flat();
export const MapUtils = {
    globalLayerIds: LAYER_IDS,
    detailLayerGroups: DETAIL_LAYER_GROUPS,
    detailLayerIds: DETAIL_LAYER_IDS,
    getTileLayer: () => {
        return new TileLayer({
            id: "tiles",
            data: [
                "https://a.tile.openstreetmap.org/{z}/{x}/{y}.png",
                "https://b.tile.openstreetmap.org/{z}/{x}/{y}.png",
                "https://c.tile.openstreetmap.org/{z}/{x}/{y}.png",
            ],
            maxRequests: 20,
            maxZoom: 19,
            minZoom: 0,
            opacity: 0.4,
            renderSubLayers: (props) => {
                const { 
                // @ts-ignore
                bbox: { west, south, east, north }, } = props.tile;
                return new BitmapLayer(props, {
                    data: null,
                    image: props.data,
                    bounds: [west, south, east, north],
                });
            },
            pickable: false,
            parameters: {
                depthTest: false,
            },
        });
    },
    getLayerData: (id) => {
        switch (id) {
            // Global map
            case 0:
                return {
                    name: "Ostatní",
                    color: [229, 97, 36],
                };
            case 1:
                return {
                    name: "Les",
                    color: [0, 82, 29],
                };
            case 2:
                return {
                    name: "Vodní plocha",
                    color: [32, 163, 255],
                };
            case 3:
                return {
                    name: "Zastavěná plocha",
                    color: [204, 187, 255],
                };
            case 4:
                return {
                    name: "Rekreační plocha",
                    color: [213, 112, 239],
                };
            case 5:
                return {
                    name: "Vinice a chmelnice",
                    color: [190, 236, 77],
                };
            case 6:
                return {
                    name: "Zahrady a sady",
                    color: [9, 128, 40],
                };
            case 7:
                return {
                    name: "Trvalý travní porost",
                    color: [61, 197, 59],
                };
            case 8:
                return {
                    name: "Orná půda",
                    color: [255, 192, 99],
                };
            // Detail maps
            case 101:
                return {
                    name: "Zahrada",
                    color: [255, 190, 190],
                };
            case 102:
                return {
                    name: "Sídelní zastavěná plocha",
                    color: [255, 0, 0],
                };
            case 103:
                return {
                    name: "Veřejná zeleň",
                    color: [240, 124, 171],
                };
            case 104:
                return {
                    name: "Travinobylinná vegetace se stromy",
                    color: [230, 230, 0],
                };
            case 105:
                return {
                    name: "Maloplošný sad",
                    color: [114, 137, 68],
                };
            case 106:
                return {
                    name: "Komunikace",
                    color: [104, 104, 104],
                };
            case 107:
                return {
                    name: "Dřevinná vegetace listnatá",
                    color: [76, 230, 0],
                };
            case 108:
                return {
                    name: "Ruderální vegetace, úhor/lado po zemědělské činnosti",
                    color: [255, 255, 190],
                };
            case 109:
                return {
                    name: "Maloplošné pole (<1 ha)",
                    color: [205, 102, 102],
                };
            case 110:
                return {
                    name: "Maloplošná vinice",
                    color: [255, 85, 0],
                };
            case 111:
                return {
                    name: "Pole se stromy",
                    color: [163, 139, 77],
                };
            case 112:
                return {
                    name: "Vinice se stromy",
                    color: [168, 56, 0],
                };
            case 113:
                return {
                    name: "Louka a pastvina",
                    color: [255, 255, 0],
                };
            case 114:
                return {
                    name: "Velkoplošné pole (1-30 ha)",
                    color: [245, 202, 122],
                };
            case 115:
                return {
                    name: "Skládka odpadu, rovojová plocha, sypaná hráz, rozvalina",
                    color: [204, 204, 204],
                };
            case 116:
                return {
                    name: "Křovina",
                    color: [170, 255, 0],
                };
            case 117:
                return {
                    name: "Vodní tok",
                    color: [0, 112, 255],
                };
            case 118:
                return {
                    name: "Těžební útvar",
                    color: [156, 156, 156],
                };
            case 119:
                return {
                    name: "Průmyslový, zemědělský, komerční areál",
                    color: [168, 0, 0],
                };
            case 120:
                return {
                    name: "Zamokřená plocha",
                    color: [190, 232, 255],
                };
            case 121:
                return {
                    name: "Přirozená vodní plocha",
                    color: [0, 77, 168],
                };
            case 122:
                return {
                    name: "Umělá vodní plocha",
                    color: [0, 197, 255],
                };
            case 123:
                return {
                    name: "Dřevinná vegetace smíšená",
                    color: [0, 168, 132],
                };
            case 124:
                return {
                    name: "Rekreační a sportovní areál",
                    color: [232, 190, 255],
                };
            case 125:
                return {
                    name: "Velkoplošné pole (>30 ha)",
                    color: [215, 194, 158],
                };
            case 126:
                return {
                    name: "Dřevinná vegetace jehličnatá",
                    color: [38, 115, 0],
                };
            case 127:
                return {
                    name: "Velkoplošná vinice",
                    color: [255, 170, 0],
                };
            case 128:
                return {
                    name: "Velkoplošný sad",
                    color: [165, 245, 122],
                };
            default:
                console.warn(`Undefined layer ID: ${id}`);
                return {
                    name: "Undefined",
                    color: [0, 0, 0],
                };
        }
    },
};
