import React from "react";
import styled from '@emotion/styled';
const VideoWrapper = styled.div `
  padding: 56.4% 0 0 0;
  position: relative;
`;
const VideoFrame = styled.iframe `
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;
const SlateVideo = ({ attributes, children, element }) => {
    return (React.createElement("div", { ...attributes },
        React.createElement("div", { contentEditable: false },
            React.createElement(VideoWrapper, null,
                React.createElement(VideoFrame, { src: `https://www.youtube.com/embed/${element.id}`, title: "Video", frameBorder: "0", allowFullScreen: true }))),
        children));
};
export default SlateVideo;
