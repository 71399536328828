import { keyframes, css } from "@emotion/react";
// =================================
// Fonts
// =================================
export const FONT_STYLE = {
    REGULAR: css `
    font-family: "Asap Condensed", sans-serif;
  `,
    SEMIBOLD: css `
    font-family: "Asap", sans-serif;
    font-weight: 600;
  `,
};
// =================================
// Fade In
// =================================
const fadeIn = keyframes `
  0% { opacity: 0; }
  100% { opacity: 1; }
`;
export const fadeInAnimation = css `
  animation: ${fadeIn} 0.3s;
`;
// =================================
// Move In
// =================================
const moveIn = keyframes `
  0% { 
    opacity: 0; 
    transform:translateY(15px);
  }
  100% { 
    opacity: 1; 
    transform:translateY(0px);
  }
`;
export const moveInAnimation = css `
  animation: ${moveIn} 0.3s;
`;
// =================================
// Move In
// =================================
const moveInLeft = keyframes `
  0% { 
    opacity: 0; 
    transform:translateX(-15px);
  }
  100% { 
    opacity: 1; 
    transform:translateX(0px);
  }
`;
const moveInRight = keyframes `
  0% { 
    opacity: 0; 
    transform:translateX(15px);
  }
  100% { 
    opacity: 1; 
    transform:translateX(0px);
  }
`;
export const moveInLeftAnimation = css `
  animation: ${moveInLeft} 0.3s;
`;
export const moveInRightAnimation = css `
  animation: ${moveInRight} 0.3s;
`;
