import React from "react";
const SlateLeaf = ({ attributes, children, leaf }) => {
    if (leaf.bold) {
        children = React.createElement("strong", null, children);
    }
    if (leaf.italic) {
        children = React.createElement("em", null, children);
    }
    return React.createElement("span", { ...attributes }, children);
};
export default SlateLeaf;
