import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { moveInAnimation } from "../utils/Styles";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { Button, Modal, Paper } from "@mui/material";
import { useGalleryContext } from "../context/GalleryContext";
import { CloseButton } from "./CloseButton";
const GalleryModal = styled(Modal) `
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const ContentWrap = styled.div `
  position: relative;
  ${moveInAnimation}
`;
const GalleryContent = styled(Paper) `
  position: relative;
  user-select: none;
  padding: 16px;
`;
const ModalCloseButton = styled(CloseButton) `
  position: absolute;
  z-index: 1;
  top: 16px;
  right: -40px;
`;
const NavLine = styled.div `
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
  align-items: center;
`;
const NavButton = styled(Button) `
  padding: 4px 32px;
`;
const ImagesWrap = styled.div `
  position: relative;
  width: 800px;
  height: 600px;
  border-radius: 4px;
  overflow: hidden;
`;
const GalleryImage = styled.img `
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  transition: opacity 0.2s;
`;
function Gallery() {
    const { galleryImages, setGalleryImages } = useGalleryContext();
    const [activeImage, setActiveImage] = useState(0);
    useEffect(() => {
        setActiveImage(0);
    }, [galleryImages]);
    const handlePrevious = () => {
        setActiveImage(Math.max(0, activeImage - 1));
    };
    const handleNext = () => {
        setActiveImage(Math.min(galleryImages.length - 1, activeImage + 1));
    };
    const handleClose = () => {
        setGalleryImages(null);
    };
    return (React.createElement(GalleryModal, { open: galleryImages && galleryImages.length > 0 ? true : false, onClose: handleClose },
        React.createElement(ContentWrap, null,
            React.createElement(ModalCloseButton, { onClick: handleClose }),
            React.createElement(GalleryContent, { elevation: 6 },
                React.createElement(ImagesWrap, null, galleryImages &&
                    galleryImages.map((image, index) => index === activeImage ? (React.createElement("a", { key: index, href: image, target: "_blank", rel: "noreferrer" },
                        React.createElement(GalleryImage, { src: image }))) : null)),
                galleryImages && galleryImages.length > 1 && (React.createElement(NavLine, null,
                    React.createElement(NavButton, { disabled: activeImage === 0, onClick: handlePrevious, color: "primary", variant: "contained", size: "large" },
                        React.createElement(NavigateBeforeIcon, { fontSize: "large" })),
                    React.createElement("h1", null, `${activeImage + 1}/${galleryImages.length}`),
                    React.createElement(NavButton, { disabled: activeImage === galleryImages.length - 1, onClick: handleNext, color: "primary", variant: "contained", size: "large" },
                        React.createElement(NavigateNextIcon, { fontSize: "large" }))))))));
}
export default Gallery;
