import React, { useState, useEffect } from "react";
import { Paper, Modal } from "@mui/material";
import DetailInfoSection from "./FutureInfoSection";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import styled from "@emotion/styled";
import axios from "axios";
import { moveInRightAnimation } from "../../utils/Styles";
import { CloseButton } from "../CloseButton";
const InfoWrap = styled.div `
  position: absolute;
  display: flex;
  top: 8px;
  bottom: 8px;
  right: 8px;
  width: 50%;
  max-width: 750px;
  ${moveInRightAnimation}
`;
const InfoCloseButton = styled(CloseButton) `
  position: absolute;
  top: 16px;
  left: -40px;
`;
const InfoContent = styled(Paper) `
  pointer-events: all;
  padding: 8px;
  overflow-y: auto;
  width: 100%;
`;
const Title = styled.h1 `
  margin-top: 8px;
  margin-bottom: 12px;
  margin-left: 8px;
`;
export const FutureInfo = ({ data, onClose }) => {
    const [hotspotData, setHotspotData] = useState(null);
    useEffect(() => {
        setHotspotData(null);
        if (data === null)
            return;
        getDownloadURL(ref(getStorage(), `hotspot/${data.id}.json`))
            .then((url) => {
            axios
                .get(url)
                .then((response) => {
                setHotspotData(response.data);
            })
                .catch(function (error) {
                console.warn("File load error:", error);
            });
        })
            .catch((error) => {
            console.warn("File URL load error:", error);
        });
    }, [data]);
    if (data === null)
        return null;
    return (React.createElement(Modal, { open: true, onClose: onClose },
        React.createElement(InfoWrap, null,
            React.createElement(InfoCloseButton, { onClick: onClose }),
            React.createElement(InfoContent, { elevation: 6 }, hotspotData !== null && (React.createElement(React.Fragment, null, hotspotData.sections.map((section, i) => {
                return (React.createElement(DetailInfoSection, { key: `section-${i}`, sectionData: section }));
            })))))));
};
