import signals from "signals";
export const getMapSignals = () => {
    return {
        zoomUpdate: new signals.Signal(),
        opacityUpdate: new signals.Signal(),
        activeLayersUpdate: new signals.Signal(),
        heatSwitchUpdate: new signals.Signal(),
        openCard: new signals.Signal(),
    };
};
export const getStorySignals = () => {
    return {
        openCard: new signals.Signal(),
        openComics: new signals.Signal(),
        completeHotspot: new signals.Signal(),
        completeSimpleSpot: new signals.Signal(),
        increaseGameLevel: new signals.Signal(),
        openGameHelp: new signals.Signal(),
    };
};
