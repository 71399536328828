import React, { useEffect, useState } from "react";
import axios from "axios";
import { Network } from "../../../utils/Network";
import styled from "@emotion/styled";
import { fadeInAnimation, FONT_STYLE } from "../../../utils/Styles";
import { Button, Paper } from "@mui/material";
import { VerticalSpacer } from "../../VerticalSpacer";
const Container = styled.div `
  ${fadeInAnimation}
`;
const Item = styled(Paper) `
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px;
  margin: 12px 8px 0 0;
`;
const ItemTitle = styled.div `
  ${FONT_STYLE.SEMIBOLD}
  margin-bottom: 8px;
`;
const ItemDescription = styled.div `
  margin-bottom: 8px;
`;
function Urls() {
    const [items, setItems] = useState([]);
    useEffect(() => {
        axios
            .get(Network.getStaticDataUrl("zdroje_k_tematu"))
            .then((response) => {
            if (response.data) {
                const values = response.data.values;
                values.shift();
                setItems(values.map((item) => {
                    return {
                        title: item[0],
                        desc: item[1],
                        url: item[2],
                    };
                }));
            }
        })
            .catch(function (error) {
            console.warn("Data load error:", error);
        });
    }, []);
    if (items.length === 0) {
        return null;
    }
    return (React.createElement(Container, null,
        React.createElement(VerticalSpacer, { height: 16 }),
        React.createElement("h2", null, "ZDROJE K\u00A0T\u00C9MATU SUCHA A VODY V\u00A0KRAJIN\u011A"),
        React.createElement("p", null, "Seznam webov\u00FDch str\u00E1nek, kde najdete dal\u0161\u00ED informace k t\u00E9mat\u016Fm, kter\u00E1 jsou s problematikou sucha spojena."),
        React.createElement(VerticalSpacer, { height: 4 }),
        items.map((item, i) => {
            return (React.createElement(Item, { elevation: 0, key: `${i}-${item.title}` },
                React.createElement(ItemTitle, null, item.title),
                item.desc && React.createElement(ItemDescription, null, item.desc),
                item.url && (React.createElement(Button, { href: item.url, download: true, target: "_blank", rel: "noreferrer", size: "small", variant: "contained", color: "primary", disableElevation: true }, "Nav\u0161t\u00EDvit"))));
        })));
}
export default Urls;
