import React from "react";
function HelpWelcome() {
    return (React.createElement(React.Fragment, null,
        React.createElement("h1", null, "\u00DAvod"),
        React.createElement("br", null),
        React.createElement("h2", null, "Z\u00E1kladn\u00ED orientace na webu"),
        React.createElement("p", null, "V\u00EDtejte na str\u00E1nk\u00E1ch P\u0159\u00EDb\u011Bh\u016F sucha. Vzd\u011Bl\u00E1vac\u00ED str\u00E1nku, p\u0159in\u00E1\u0161ej\u00EDc\u00ED informace o problematice sucha a nakl\u00E1d\u00E1n\u00ED s vodou, je mo\u017En\u00E9 proch\u00E1zet dv\u011Bma zp\u016Fsoby:"),
        React.createElement("ol", null,
            React.createElement("li", null, "Jako znalostn\u00ED datab\u00E1zi, p\u0159in\u00E1\u0161ej\u00EDc\u00ED data a informace z\u00EDskan\u00E9 b\u011Bhem \u010Dty\u0159let\u00E9ho v\u011Bdeck\u00E9ho v\u00FDzkumu v sedmi lokalit\u00E1ch na ji\u017En\u00ED Morav\u011B. Datab\u00E1ze je ur\u010Dena p\u0159edev\u0161\u00EDm dosp\u011Bl\u00E9mu publiku, odborn\u00EDk\u016Fm, ekolog\u016Fm a \u0161ir\u0161\u00ED ve\u0159ejnosti se z\u00E1jmem o danou problematiku. Zde obsa\u017Een\u00E1 data a informace lze vyu\u017E\u00EDt jako podklad pro EVVO a jako dopln\u011Bk k pracovn\u00EDm list\u016Fm."),
            React.createElement("br", null),
            React.createElement("li", null, "Jako p\u0159\u00EDb\u011Bhov\u00E9 proch\u00E1zen\u00ED stejn\u00FDmi lokalitami, kter\u00FDmi n\u00E1s provede kapka vody l\u00E9taj\u00EDc\u00ED nad krajinou ji\u017En\u00ED Moravy. P\u0159\u00EDb\u011Bhov\u00E9 pojet\u00ED je ur\u010Deno p\u0159edev\u0161\u00EDm \u0161kol\u00E1k\u016Fm, student\u016Fm a d\u011Btem. Spolu s pracovn\u00EDmi listy m\u016F\u017Ee b\u00FDt vyu\u017Eito pro environment\u00E1ln\u00ED vzd\u011Bl\u00E1v\u00E1n\u00ED d\u011Bt\u00ED ve v\u011Bku 10 - 15 let.")),
        React.createElement("p", null, "Mezi t\u011Bmito variantami webu se lze kdykoli p\u0159ep\u00EDnat v lev\u00E9m horn\u00EDm rohu prohl\u00ED\u017Ee\u010De tla\u010D\u00EDtky \u201CP\u0159\u00EDb\u011Bh\u201D a \u201CHlavn\u00ED mapa\u201D."),
        React.createElement("p", null, "Obsa\u017Een\u00E1 data a informace vych\u00E1zej\u00ED ze \u010Dty\u0159let\u00E9ho ter\u00E9nn\u00EDho v\u00FDzkumu v oblasti a z re\u0161er\u0161\u00ED dal\u0161\u00EDch zdroj\u016F. Citace z rozhovor\u016F, uv\u00E1d\u011Bn\u00E9 v textech, jsou anonymizov\u00E1ny krom\u011B t\u011Bch p\u0159\u00EDpad\u016F, kdy si autor/ka dan\u00E9 v\u00FDpov\u011Bdi anonymizaci nep\u0159\u00E1la."),
        React.createElement("img", { style: {
                maxWidth: "300px",
            }, src: "https://firebasestorage.googleapis.com/v0/b/interactive-sucho.appspot.com/o/help%2Fwelcome_1.png?alt=media&token=ba56c3a3-3b20-482a-8c73-f5f55cb58e4f" }),
        React.createElement("p", null, "Pro v\u00EDce informac\u00ED o variant\u00E1ch webu klikn\u011Bte na po\u017Eadovanou z\u00E1lo\u017Eku naho\u0159e tohoto okna s n\u00E1pov\u011Bdou."),
        React.createElement("h2", null, "Datab\u00E1ze"),
        React.createElement("p", null, "Pod tla\u010D\u00EDtkem \u201CDatab\u00E1ze\u201D se nach\u00E1z\u00ED z\u00E1lo\u017Eky Slovn\u00EDk odborn\u00FDch v\u00FDraz\u016F, Odkazy na extern\u00ED zdroje, Materi\u00E1ly ke sta\u017Een\u00ED a Pracovn\u00ED listy P\u0159\u00EDb\u011Bhy sucha."),
        React.createElement("img", { style: {
                maxWidth: "120px",
            }, src: "https://firebasestorage.googleapis.com/v0/b/interactive-sucho.appspot.com/o/help%2Fwelcome_2.png?alt=media&token=fac7e62f-7e46-4f65-b932-492c93a28af8" }),
        React.createElement("br", null),
        React.createElement("br", null),
        React.createElement("h2", null, "N\u00E1pov\u011Bda"),
        React.createElement("p", null, "Pro op\u011Btovn\u00E9 zobrazen\u00ED n\u00E1pov\u011Bdy v jak\u00E9koli \u010D\u00E1sti webu stiskn\u011Bte otazn\u00EDk v lev\u00E9 horn\u00ED \u010D\u00E1sti webu."),
        React.createElement("img", { style: {
                maxWidth: "50px",
            }, src: "https://firebasestorage.googleapis.com/v0/b/interactive-sucho.appspot.com/o/help%2Fwelcome_3.png?alt=media&token=80007b51-5d74-40bf-a144-a8702b2ae2a0" }),
        React.createElement("p", null, "Pro zav\u0159en\u00ED n\u00E1pov\u011Bdy (a jak\u00E9hokoli okna webu) stiskn\u011Bte k\u0159\u00ED\u017Eek v prav\u00E9 horn\u00ED \u010D\u00E1sti tohoto okna."),
        React.createElement("img", { style: {
                maxWidth: "50px",
            }, src: "https://firebasestorage.googleapis.com/v0/b/interactive-sucho.appspot.com/o/help%2Fwelcome_4.png?alt=media&token=b0656105-cb78-4a84-bf49-3c38a3873cef" }),
        React.createElement("p", null, "Pro znovuotev\u0159en\u00ED jak\u00E9hokoliv zav\u0159en\u00E9ho okna klikn\u011Bte na tla\u010D\u00EDtko \u201C+\u201D na okraji prohl\u00ED\u017Ee\u010De."),
        React.createElement("img", { style: {
                maxWidth: "50px",
            }, src: "https://firebasestorage.googleapis.com/v0/b/interactive-sucho.appspot.com/o/help%2Fwelcome_5.png?alt=media&token=212c7569-3ded-4965-97b8-994af57c3d58" })));
}
export default HelpWelcome;
