import React from "react";
function HelpMap() {
    return (React.createElement(React.Fragment, null,
        React.createElement("h1", null, "Hlavn\u00ED mapa"),
        React.createElement("p", null, "Hlavn\u00ED mapa zobrazuje sledovan\u00E9 lokality v \u0161ir\u0161\u00EDm kontextu ji\u017En\u00ED Moravy. Pohyb na map\u011B je obdobn\u00FD, jako na b\u011B\u017En\u00FDch webech s mapami: pohybujeme se my\u0161\u00ED, p\u0159ibli\u017Eujeme se a oddalujeme kole\u010Dkem. Pro porovn\u00E1n\u00ED v\u00FDvoje krajiny v minulosti a sou\u010Dasnosti je mo\u017En\u00E9 pou\u017E\u00EDt posuvn\u00EDk v lev\u00E9m doln\u00EDm rohu mapy. Zde je tak\u00E9 legenda, umo\u017E\u0148uj\u00EDc\u00ED vyp\u00EDn\u00E1n\u00ED a zap\u00EDn\u00E1n\u00ED jednotliv\u00FDch vrstev mapy."),
        React.createElement("img", { style: {
                maxWidth: "200px",
            }, src: "https://firebasestorage.googleapis.com/v0/b/interactive-sucho.appspot.com/o/help%2Fmap_1.png?alt=media&token=67272e86-a011-453a-9a29-62d360fd6b5b" }),
        React.createElement("p", null, "Hlavn\u00EDmi sledovan\u00FDmi lokalitami s p\u0159idru\u017Een\u00FDmi t\u00E9maty jsou:"),
        React.createElement("ul", null,
            React.createElement("li", null,
                React.createElement("strong", null, "Doln\u00ED Dunajovice"),
                ": voda v\u00A0zem\u011Bd\u011Blstv\u00ED; vinohradnictv\u00ED a vina\u0159stv\u00ED; pam\u011B\u0165 krajiny"),
            React.createElement("li", null,
                React.createElement("strong", null, "Doman\u00EDn"),
                ": p\u016Fda; pr\u016Fmyslov\u00E9 zem\u011Bd\u011Blstv\u00ED; zem\u011Bd\u011Blsk\u00E9 dotace"),
            React.createElement("li", null,
                React.createElement("strong", null, "Nov\u00E9 Ml\u00FDny"),
                ": vodohospod\u00E1\u0159sk\u00E9 \u00FApravy; velk\u00E1 vodn\u00ED d\u00EDla a jejich dopady"),
            React.createElement("li", null,
                React.createElement("strong", null, "Syrov\u00EDn - T\u011Bmice"),
                ": vodohospod\u00E1\u0159sk\u00E1 infrastruktura (zdroje pitn\u00E9 vody, vodovod, kanalizace)"),
            React.createElement("li", null,
                React.createElement("strong", null, "\u0160akvice"),
                ": lu\u017En\u00ED les; lesnictv\u00ED a klimatick\u00E1 zm\u011Bna"),
            React.createElement("li", null,
                React.createElement("strong", null, "\u0160ardice"),
                ": adaptace na klimatickou zm\u011Bnu: agroenvironment\u00E1ln\u011B-klimatick\u00E1 opat\u0159en\u00ED (AEKO), komplexn\u00ED pozemkov\u00E9 \u00FApravy")),
        React.createElement("p", null, "Po rozkliknut\u00ED barevn\u00E9ho n\u00E1zvu v map\u011B se otev\u0159e detailn\u00ED mapa lokality se zpracovan\u00FDmi tematick\u00FDmi okruhy v z\u00E1lo\u017Ek\u00E1ch v prav\u00E9 \u010D\u00E1sti webu."),
        React.createElement("img", { style: {
                width: "100%",
                maxWidth: "500px",
            }, src: "https://firebasestorage.googleapis.com/v0/b/interactive-sucho.appspot.com/o/help%2Fmap_2_1.png?alt=media&token=b2e08d67-8abf-44ae-a800-b0380eae7310" }),
        React.createElement("p", null, "Zp\u011Bt na hlavn\u00ED mapu se dostaneme p\u0159es tla\u010D\u00EDtko Hlavn\u00ED mapa v lev\u00E9 horn\u00ED \u010D\u00E1sti webu."),
        React.createElement("img", { style: {
                maxWidth: "150px",
            }, src: "https://firebasestorage.googleapis.com/v0/b/interactive-sucho.appspot.com/o/help%2Fmap_3.png?alt=media&token=05976b92-2c5c-4062-b961-30dc5196ee95" })));
}
export default HelpMap;
