import React, { useCallback, useMemo } from "react";
import { Slate, Editable, withReact, } from "slate-react";
import { createEditor } from "slate";
import SlateElement from "./SlateElement/SlateElement";
import SlateLeaf from "./SlateLeaf/SlateLeaf";
const SlateViewer = ({ content }) => {
    const editor = useMemo(() => withReact(createEditor()), []);
    const renderElement = useCallback((props) => React.createElement(SlateElement, { ...props }), []);
    const renderLeaf = useCallback((props) => React.createElement(SlateLeaf, { ...props }), []);
    return (React.createElement(Slate, { editor: editor, value: content, onChange: () => null },
        React.createElement(Editable, { renderElement: renderElement, renderLeaf: renderLeaf, readOnly: true })));
};
export { SlateViewer };
