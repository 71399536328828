import React from "react";
import { AccordionDetails, Typography, Accordion, AccordionSummary, } from "@mui/material";
import styled from "@emotion/styled";
import { SlateViewer } from "../Slate/SlateViewer";
const SectionWrap = styled.div `
  width: 100%;
`;
const CustomAccordionSummary = styled(AccordionSummary) `
  pointer-events: none;
`;
function FutureInfoSection({ sectionData }) {
    return (React.createElement(Accordion, { expanded: true },
        React.createElement(CustomAccordionSummary, null,
            React.createElement(Typography, null, sectionData.title)),
        React.createElement(AccordionDetails, null,
            React.createElement(SectionWrap, null,
                React.createElement(SlateViewer, { content: sectionData.data })))));
}
export default FutureInfoSection;
