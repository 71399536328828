import React from "react";
function HelpStory() {
    return (React.createElement(React.Fragment, null,
        React.createElement("h1", null, "P\u0159\u00EDb\u011Bh"),
        React.createElement("p", null, "P\u0159\u00EDb\u011Bhov\u00E1 verze je tak\u00E9 um\u00EDst\u011Bna do mapy. T\u00E9matem n\u00E1s provede v \u0161esti samostatn\u00FDch p\u0159\u00EDb\u011Bz\u00EDch, kter\u00E9 obr\u00E1zkovou formou vypr\u00E1v\u00ED p\u0159\u00EDhody spojen\u00E9 s problematikou vody a sucha. Ka\u017Ed\u00FD p\u0159\u00EDb\u011Bh odpov\u00EDd\u00E1 jedn\u00E9 ze zkouman\u00FDch lokalit, mezi kter\u00FDmi n\u00E1s po map\u011B naviguje kapka vody."),
        React.createElement("p", null, "Kapku je mo\u017En\u00E9 ovl\u00E1dat \u0161ipkami (nebo WASD). K orientaci slou\u017E\u00ED minimapa, nebo mal\u00E9 naviga\u010Dn\u00ED \u0161ipky vedle let\u00EDc\u00ED kapky. \u00DAkolem kapky je nav\u0161t\u00EDvit v\u0161echny hlavn\u00ED lokality - obce (\u017Elut\u00E1 \u0161ipka) v libovoln\u00E9m po\u0159ad\u00ED. Jakmile s kapkou do dan\u00E9 obce doputujeme, spust\u00ED se obr\u00E1zkov\u00FD p\u0159\u00EDb\u011Bh. Mezi jednotliv\u00FDmi obr\u00E1zkami listujeme pomoc\u00ED zelen\u00FDch \u0161ipek v doln\u00ED li\u0161t\u011B."),
        React.createElement("img", { style: {
                width: "100%",
                maxWidth: "500px",
            }, src: "https://firebasestorage.googleapis.com/v0/b/interactive-sucho.appspot.com/o/help%2Fstory_1.png?alt=media&token=a48ca02f-0ac7-4912-b9f6-e882c61fc8cc" }),
        React.createElement("p", null, "V obr\u00E1zc\u00EDch jsou rozm\u00EDst\u011Bna tla\u010D\u00EDtka (zelen\u00E9 hv\u011Bzdy), pod kter\u00FDmi si m\u016F\u017Eeme dohledat podrobn\u00E9 informace k jednotliv\u00FDm t\u00E9mat\u016Fm. Po kliknut\u00ED na zelenou hv\u011Bzdu v\u017Edy z\u00EDsk\u00E1v\u00E1me sb\u011Bratelskou kartu, kter\u00E1 se ukl\u00E1d\u00E1 do alba. Album si m\u016F\u017Eeme kdykoli prohl\u00E9dnout stisknut\u00EDm tla\u010D\u00EDtka s b\u00EDlou hv\u011Bzdou."),
        React.createElement("img", { style: {
                maxWidth: "100px",
            }, src: "https://firebasestorage.googleapis.com/v0/b/interactive-sucho.appspot.com/o/help%2Fstory_2.png?alt=media&token=31b2c5d9-8ea2-4291-8758-ba652c2a6b87" }),
        React.createElement("p", null, "Po p\u0159e\u010Dten\u00ED p\u0159\u00EDb\u011Bhu se spust\u00ED jednoduch\u00E1 hra. Po jej\u00EDm \u00FAsp\u011B\u0161n\u00E9m dohr\u00E1n\u00ED se p\u0159esune jedna z l\u00E9tac\u00EDch kapek do z\u00E1sobn\u00EDku \u201CBudoucnost\u201D v prav\u00E9m horn\u00EDm rohu webu."),
        React.createElement("img", { style: {
                maxWidth: "300px",
            }, src: "https://firebasestorage.googleapis.com/v0/b/interactive-sucho.appspot.com/o/help%2Fstory_3.png?alt=media&token=40344f55-0309-4e50-81f1-ced59c0cc72f" }),
        React.createElement("p", null, "Tla\u010D\u00EDtko budoucnost ukazuje stylizovan\u00FD pohled do krajiny budoucnosti, kde si m\u016F\u017Eeme podle po\u010Dtu nast\u0159\u00E1dan\u00FDch kapek zap\u00EDnat a vyp\u00EDnat detaily budouc\u00ED krajiny a p\u0159e\u010D\u00EDst si texty o d\u016Fsledc\u00EDch klimatick\u00E9 zm\u011Bny."),
        React.createElement("h2", null, "Bonusov\u00E9 sb\u011Bratelsk\u00E9 karty"),
        React.createElement("p", null, "Krom\u011B \u0161esti hlavn\u00EDch lokalit, kde se p\u0159\u00EDb\u011Bhy odehr\u00E1vaj\u00ED, m\u016F\u017Eeme nav\u0161t\u00EDvit bonusov\u00E1 m\u00EDsta (oran\u017Eov\u00E1 \u0161ipka), kde po vy\u0159e\u0161en\u00ED puzzle z\u00EDsk\u00E1v\u00E1me sb\u011Bratelskou kartu. Nasb\u00EDran\u00E9 karty je pak op\u011Bt mo\u017En\u00E9 si prohl\u00E9dnout v albu v prav\u00E9m horn\u00EDm rohu webu."),
        React.createElement("h2", null, "Registrace / P\u0159ihla\u0161ov\u00E1n\u00ED"),
        React.createElement("p", null, "Postup p\u0159\u00EDb\u011Bhu se ukl\u00E1d\u00E1 po registraci v prav\u00E9m horn\u00EDm rohu webu. Web je mo\u017En\u00E9 proch\u00E1zet i bez registrace, postup se v\u0161ak neukl\u00E1d\u00E1 a po op\u011Btovn\u00E9m nav\u0161t\u00EDven\u00ED webu se v\u017Edy za\u010D\u00EDn\u00E1 znovu."),
        React.createElement("img", { style: {
                maxWidth: "300px",
            }, src: "https://firebasestorage.googleapis.com/v0/b/interactive-sucho.appspot.com/o/help%2Fstory_4.png?alt=media&token=e13905f9-2a80-4a6d-8cdd-4d646e8a50ce" })));
}
export default HelpStory;
