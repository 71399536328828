import { MapUtils } from "./MapUtils";
export const DEFAULT_LAYER_COLOR = new Uint8Array([160, 160, 160]);
const componentToHex = (c) => {
    var hex = c.toString(16);
    return hex.length === 1 ? "0" + hex : hex;
};
export const Colors = {
    getMapFillColor: (layerId) => {
        return new Uint8Array(MapUtils.getLayerData(layerId).color);
    },
    getMapFillHexColor: (layerId) => {
        const { color } = MapUtils.getLayerData(layerId);
        return ("#" +
            componentToHex(color[0]) +
            componentToHex(color[1]) +
            componentToHex(color[2]));
    },
};
