import React, { useContext } from "react";
export var HelpModalState;
(function (HelpModalState) {
    HelpModalState[HelpModalState["CLOSED"] = 0] = "CLOSED";
    HelpModalState[HelpModalState["WELCOME"] = 1] = "WELCOME";
    HelpModalState[HelpModalState["MAP"] = 2] = "MAP";
    HelpModalState[HelpModalState["STORY"] = 3] = "STORY";
    HelpModalState[HelpModalState["GAME"] = 4] = "GAME";
})(HelpModalState || (HelpModalState = {}));
export const HelpContext = React.createContext({
    helpModalState: null,
    setHelpModalState: () => { },
});
export const useHelpContext = () => {
    return useContext(HelpContext);
};
