import { GlobalStyle, Firebase, AppContainer } from "@sucho/shared";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./components/App";
import Map from "./components/Map";

// Init Firebase
Firebase.init();

// Init map
new Map();

// React layer
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <GlobalStyle />
    <AppContainer>
      <App />
    </AppContainer>
  </React.StrictMode>
);
