import { Button, IconButton, Paper } from "@mui/material";
import React, { useState } from "react";
import styled from "@emotion/styled";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import HelpIcon from "@mui/icons-material/Help";
import { About } from "../About";
import Database from "../Database/Database";
import { SoundSwitch } from "./SoundSwitch";
import { HorizontalSpacer } from "../HorizontalSpacer";
import { useHelpContext } from "../../context/HelpContext";
const MainMenuContainer = styled(Paper) `
  position: absolute;
  display: flex;
  pointer-events: all;
  top: 8px;
  left: 8px;
  padding: 6px;
`;
const MainMenu = ({ children, helpState, soundSwitch = false }) => {
    const [openDatabase, setOpenDatabase] = useState(false);
    const [openAbout, setOpenAbout] = useState(false);
    const { setHelpModalState } = useHelpContext();
    return (React.createElement(React.Fragment, null,
        React.createElement(MainMenuContainer, { elevation: 6 },
            children,
            React.createElement(HorizontalSpacer, { width: 6 }),
            React.createElement(Button, { size: "small", variant: "contained", color: "primary", disableElevation: true, onClick: () => {
                    setOpenDatabase(true);
                }, onFocus: (e) => {
                    e.target.blur();
                } }, "Datab\u00E1ze"),
            React.createElement(HorizontalSpacer, { width: 6 }),
            React.createElement(Button, { size: "small", variant: "contained", color: "primary", disableElevation: true, onClick: (e) => {
                    setOpenAbout(true);
                }, onFocus: (e) => {
                    e.target.blur();
                } }, "O projektu"),
            React.createElement(HorizontalSpacer, { width: 6 }),
            React.createElement(IconButton, { title: "Help", size: "small", color: "primary", onFocus: (e) => {
                    e.target.blur();
                }, onClick: () => {
                    setHelpModalState(helpState);
                } },
                React.createElement(HelpIcon, null)),
            React.createElement(HorizontalSpacer, { width: 6 }),
            React.createElement(IconButton, { title: "Fullscreen", size: "small", color: "primary", onFocus: (e) => {
                    e.target.blur();
                }, onClick: () => {
                    if (!document.fullscreenElement) {
                        document.documentElement.requestFullscreen();
                    }
                    else {
                        if (document.exitFullscreen) {
                            document.exitFullscreen();
                        }
                    }
                } },
                React.createElement(FullscreenIcon, null)),
            soundSwitch && (React.createElement(React.Fragment, null,
                React.createElement(HorizontalSpacer, { width: 6 }),
                React.createElement(SoundSwitch, null)))),
        React.createElement(Database, { open: openDatabase, onClose: () => {
                setOpenDatabase(false);
            } }),
        React.createElement(About, { open: openAbout, onClose: () => {
                setOpenAbout(false);
            } })));
};
export { MainMenu };
