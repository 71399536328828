export const SIMPLE_SPOTS = [
    {
        id: 0,
        card: "4kPiUgTiKnmSFGlrt1wY",
        properties: {
            name: "Větrná elektrárna Břežany u Znojma",
        },
        pos: [16.3699756, 48.8829636],
    },
    {
        id: 1,
        card: "T9YEKcFxHgmc57ffDPOS",
        properties: {
            name: "Zavlažovací kanál Dyjákovice - Hevlín",
        },
        pos: [16.3545469, 48.7709036],
    },
    {
        id: 2,
        card: "Vq6yiKcd0oSldpXoB53C",
        properties: {
            name: "Pohořelické rybníky",
        },
        pos: [16.5470503, 48.9613806],
    },
    {
        id: 3,
        card: "Zf5aQp8TJyQcg0nCY5Kr",
        properties: {
            name: "Slanisko Novosedly",
        },
        pos: [16.4973517, 48.8392356],
    },
    {
        id: 4,
        card: "MlmqEcWq9W6OV4JQKTBD",
        properties: {
            name: "Pozdřanská step - vyhlídka",
        },
        pos: [16.6424356, 48.9429425],
    },
    {
        id: 5,
        card: "O2TeOxSdIgWYE7WfzaNj",
        properties: {
            name: "Mandloňové arboretum",
        },
        pos: [16.7431403, 48.9542267],
    },
    {
        id: 6,
        card: "C25K0uhGXk2T7O1M3NKO",
        properties: {
            name: "Nesyt - největší rybník na Moravě",
        },
        pos: [16.7252972, 48.7748117],
    },
    {
        id: 7,
        card: "xxUWNUSot03gOF80cPXb",
        properties: {
            name: "Národní přírodní památka Větrníky",
        },
        pos: [16.9808483, 49.1965011],
    },
    {
        id: 8,
        card: "o9cwAW88nWfPvyUlVCBW",
        properties: {
            name: "Brdo - nejvyšší kopec Chřibů",
        },
        pos: [17.3091369, 49.1710567],
    },
    {
        id: 9,
        card: "lRffemc4DP0r8GbjdWh8",
        properties: {
            name: "Jezera vzniklá po těžbě štěrkopísku",
        },
        pos: [17.5154753, 49.1416203],
    },
    {
        id: 10,
        card: "d1lPstdfsfvEkpTksg1u",
        properties: {
            name: "Revitalizace Trkmanky",
        },
        pos: [16.8369475, 48.9136039],
    },
    {
        id: 11,
        card: "59E4TsjXpoimOEB5ql0V",
        properties: {
            name: "Vodní nádrž Koryčany - zdroj pitné vody",
        },
        pos: [17.2007342, 49.1140178],
    },
    {
        id: 12,
        card: "REqb7GuAivVMGa5gD9An",
        properties: {
            name: "Těžba zemního plynu a ropy u Ždánického lesa",
        },
        pos: [16.9167283, 49.0556275],
    },
    {
        id: 13,
        card: "RS1BYXrqQEgZZt0zjpqa",
        properties: {
            name: "Haluzický rybník a nové tůně",
        },
        pos: [17.1037311, 49.0718569],
    },
    {
        id: 14,
        card: "6I1hEcH6pgzmx8S8sRvS",
        properties: {
            name: "Stromová kaple Hýsly",
        },
        pos: [17.1911761, 49.0196906],
    },
    {
        id: 15,
        card: "ddR7bqz2rUXYyenipplY",
        properties: {
            name: "Neregulovaný tok Olšavy se strmými břehy",
        },
        pos: [17.5154356, 49.0442111],
    },
    {
        id: 16,
        card: "kQcgq0S5kstq6gigv4h5",
        properties: {
            name: "Životaschopná populace syslů na letišti v Břeclavi",
        },
        pos: [16.8924972, 48.7908306],
    },
    {
        id: 17,
        card: "s5xPuTuEobUnsOWKL49A",
        properties: {
            name: "Lužní les a větvící se toky v nivě Dyje a Moravy",
        },
        pos: [16.9465372, 48.6779992],
    },
    {
        id: 18,
        card: "kckiNuSkrwxyM9hhtavt",
        properties: {
            name: "Rybníkářství Hodonín",
        },
        pos: [17.0710325, 48.8591614],
    },
    {
        id: 19,
        card: "GfpGnfna7jomtePBfvGU",
        properties: {
            name: "Bzenecká doubrava s borovicemi",
        },
        pos: [17.2221436, 48.9486931],
    },
    {
        id: 20,
        card: "TkVpp9y0vNALMJMNKIBW",
        properties: {
            name: "Osypané břehy - přírodní meandr Moravy",
        },
        pos: [17.2787375, 48.918315],
    },
    {
        id: 21,
        card: "fbpXC0r5Vr7JJvRN37xS",
        properties: {
            name: "Národní přírodní rezervace Čertoryje",
        },
        pos: [17.4167722, 48.8552578],
    },
    {
        id: 22,
        card: "8cTG7rHcr6NkaxyeIjoy",
        properties: {
            name: "Soustava vodních mlýnů na Veličce",
        },
        pos: [17.5683842, 48.8751022],
    },
    {
        id: 23,
        card: "1B1G0Exw6HOGfXWkFvhI",
        properties: {
            name: "Přírodní památka Pánov",
        },
        pos: [17.1419014, 48.8863858],
    },
    {
        id: 24,
        card: "AAIUvoHJ1iuwqf7bQVG0",
        properties: {
            name: "Pálava",
        },
        pos: [16.6499908, 48.8694775],
    },
    {
        id: 25,
        card: "vAXVqdlCGnd55TayVb8W",
        properties: {
            name: "Komponovaná krajina Lednicko-valtického areálu",
        },
        pos: [16.7927864, 48.7503886],
    },
    {
        id: 26,
        card: "OsmPUaiUoogl19g9rZax",
        properties: {
            name: "Baťův kanál",
        },
        pos: [17.3912006, 48.98326],
    },
    {
        id: 27,
        card: "SuXUCkSlLaEvUF04CbV0",
        properties: {
            name: "Lokalita na Adamcích s nedalekou daňčí oborou",
        },
        pos: [16.9953669, 49.0052147],
    },
];
