import React, { Fragment } from "react";
import { Modal, Paper, Button } from "@mui/material";
import styled from "@emotion/styled";
import { moveInAnimation } from "../../utils/Styles";
import { HorizontalSpacer } from "../HorizontalSpacer";
import { CloseButton } from "../CloseButton";
import HelpWelcome from "./HelpSections/HelpWelcome";
import HelpMap from "./HelpSections/HelpMap";
import HelpStory from "./HelpSections/HelpStory";
import HelpGame from "./HelpSections/HelpGame";
import { HelpModalState, useHelpContext } from "../../context/HelpContext";
const HelpModal = styled(Modal) `
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const ContentWrap = styled.div `
  position: relative;
  height: 80%;
  width: 90%;
  max-width: 800px;
  margin: 40px;
  display: flex;
  ${moveInAnimation}
`;
const ModalCloseButton = styled(CloseButton) `
  position: absolute;
  z-index: 1;
  top: 16px;
  right: -40px;
`;
const Content = styled(Paper) `
  position: relative;
  padding: 28px;
  display: flex;
  flex: 1;
  flex-direction: column;
`;
const Menu = styled.div `
  display: flex;
  margin-bottom: 16px;
`;
const MenuButton = styled(Button) `
  height: 36px;
`;
const HelpSection = styled.div `
  padding-right: 20px;
  overflow-y: auto;
`;
const MENU_ITEMS = [
    { label: "Úvod", id: HelpModalState.WELCOME },
    { label: "Mapa", id: HelpModalState.MAP },
    { label: "Příběh", id: HelpModalState.STORY },
    { label: "Hra", id: HelpModalState.GAME },
];
function Help() {
    const { helpModalState, setHelpModalState } = useHelpContext();
    const handleClose = () => {
        setHelpModalState(HelpModalState.CLOSED);
    };
    return (React.createElement(HelpModal, { open: helpModalState !== HelpModalState.CLOSED, onClose: handleClose },
        React.createElement(ContentWrap, null,
            React.createElement(ModalCloseButton, { onClick: handleClose }),
            React.createElement(Content, { elevation: 6 },
                React.createElement(Menu, null, MENU_ITEMS.map((item, index) => (React.createElement(Fragment, { key: item.label },
                    React.createElement(MenuButton, { variant: helpModalState === item.id ? "outlined" : "contained", color: "primary", disableElevation: true, onClick: () => {
                            setHelpModalState(item.id);
                        } }, item.label),
                    index !== MENU_ITEMS.length - 1 && (React.createElement(HorizontalSpacer, { width: 6 })))))),
                React.createElement(HelpSection, null,
                    helpModalState === HelpModalState.WELCOME && React.createElement(HelpWelcome, null),
                    helpModalState === HelpModalState.MAP && React.createElement(HelpMap, null),
                    helpModalState === HelpModalState.STORY && React.createElement(HelpStory, null),
                    helpModalState === HelpModalState.GAME && React.createElement(HelpGame, null))))));
}
export default Help;
