import React from "react";
import styled from "@emotion/styled";
import { useGalleryContext } from "../../../../context/GalleryContext";
const ImageWrap = styled.div `
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const Image = styled.img `
  cursor: pointer;
  display: block;
  max-width: 50%;
  max-height: 200px;
`;
const SlateImage = ({ attributes, element }) => {
    const { setGalleryImages } = useGalleryContext();
    const openGallery = () => {
        setGalleryImages([element.url]);
    };
    return (React.createElement(ImageWrap, { ...attributes },
        React.createElement(Image, { src: element.url, alt: "", onClick: openGallery })));
};
export default SlateImage;
