export const FONT = {
    ASAP: "Asap, sans-serif",
    ASAP_CONDENSED: "Asap Condensed, sans-serif",
};
export const MuiTheme = {
    typography: {
        fontFamily: FONT.ASAP,
    },
    palette: {
        primary: {
            main: "#91bf1f",
            contrastText: "#f9f7e4",
        },
        secondary: {
            main: "#f9f7e4",
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    fontSize: "1rem",
                    lineHeight: "normal",
                    fontWeight: 600,
                    textTransform: "none",
                    minWidth: "auto",
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    backgroundColor: "#f9f7e4",
                    border: "2px solid #526d01",
                    borderRadius: "8px",
                    "&:focus": {
                        outline: "none",
                    },
                },
                elevation6: {
                    boxShadow: "4px 6px 10px rgba(0, 0, 0, 0.7)",
                },
            },
        },
        MuiAccordion: {
            styleOverrides: {
                root: {
                    border: "2px solid #91bf1f",
                    borderRadius: "6px",
                    boxShadow: "none",
                    margin: "0 0 6px",
                    "&.Mui-expanded": {
                        margin: "0 0 6px",
                    },
                },
            },
        },
        MuiAccordionSummary: {
            styleOverrides: {
                root: {
                    backgroundColor: "#91bf1f",
                    color: "#f9f7e4",
                },
            },
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    padding: 0,
                    paddingRight: "4px",
                    userSelect: "none",
                    fontSize: "0.8rem",
                },
            },
        },
        MuiFormControlLabel: {
            styleOverrides: {
                root: {
                    margin: 0,
                },
                label: {
                    userSelect: "none",
                    fontSize: "0.8rem",
                    lineHeight: "0.8rem",
                },
            },
        },
    },
};
