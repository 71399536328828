import { Global, css } from "@emotion/react";
import React from "react";
import { FONT_STYLE } from "../utils/Styles";
function GlobalStyle() {
    return (React.createElement(Global, { styles: css `
        html,
        body,
        #root {
          width: 100%;
          height: 100%;
          overflow: hidden;
        }

        body {
          ${FONT_STYLE.REGULAR}
          margin: 0;
          font-size: 14px;
          color: #000000;
          background-color: #ffffff;
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          ${FONT_STYLE.SEMIBOLD}
          margin: 0;
        }

        button {
          ${FONT_STYLE.SEMIBOLD}
          font-size: 1rem;
          color: #f9f7e4;
          background-color: #91bf1f;
          cursor: pointer;
          user-select: none;
          border: none;
          padding: 8px 16px;
          border-radius: 4px;

          :hover {
            background-color: #648516;
          }

          :disabled {
            cursor: default;
            pointer-events: none;
            opacity: 0.2;
          }
        }

        input {
          cursor: pointer;
        }

        table {
          border-collapse: collapse;
        }

        td {
          padding: 10px;
          border: 2px solid #ddd;
        }

        hr {
          border: 1px solid #91bf1f;
        }

        div:focus {
          outline: none;
        }

        #deckgl-overlay {
          top: 0;
        }

        #root {
          position: relative;
          z-index: 2;
          pointer-events: none;
        }
      ` }));
}
export { GlobalStyle };
