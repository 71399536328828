import React from "react";
import styled from "@emotion/styled";
import { Modal, Paper } from "@mui/material";
import { isTablet } from "react-device-detect";
import { useWindowSize } from "usehooks-ts";
const Wrapper = styled(Modal) `
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f9f7e4;
`;
const Message = styled(Paper) `
  padding: 40px;
`;
export const TabletPortraitOverlay = () => {
    const { width, height } = useWindowSize();
    const isPortrait = width < height;
    if (isTablet && isPortrait) {
        return (React.createElement(Wrapper, { open: true },
            React.createElement(Message, null,
                React.createElement("h2", null, "Oto\u010Dte za\u0159\u00EDzen\u00ED do horizont\u00E1ln\u00ED polohy"))));
    }
    return null;
};
