import React from "react";
import { Modal, Paper } from "@mui/material";
import styled from "@emotion/styled";
import { moveInAnimation } from "../utils/Styles";
import { CloseButton } from "./CloseButton";
const AboutModal = styled(Modal) `
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const ContentWrap = styled.div `
  position: relative;
  width: 70%;
  max-width: 800px;
  height: 90%;
  ${moveInAnimation}
`;
const ModalCloseButton = styled(CloseButton) `
  position: absolute;
  z-index: 1;
  top: 16px;
  right: -40px;
`;
const ContentPaper = styled(Paper) `
  position: absolute;
  padding: 20px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-y: auto;
`;
const About = ({ open, onClose }) => {
    return (React.createElement(AboutModal, { open: open, onClose: onClose },
        React.createElement(ContentWrap, null,
            React.createElement(ModalCloseButton, { onClick: onClose }),
            React.createElement(ContentPaper, null,
                React.createElement("h1", null, "O Projektu"),
                React.createElement("p", null,
                    "Vzd\u011Bl\u00E1vac\u00ED webov\u00E1 str\u00E1nka P\u0159\u00EDb\u011Bhy sucha je jedn\u00EDm z v\u00FDstup\u016F \u010Dty\u0159let\u00E9ho (2019 \u2013 2022) v\u00FDzkumn\u00E9ho projektu P\u0159\u00EDb\u011Bhy sucha",
                    " ",
                    React.createElement("a", { href: "https://www.pribehysucha.cuni.cz/", target: "_blank" }, "pribehysucha.cuni.cz"),
                    ". Je ur\u010Dena environment\u00E1ln\u00ED v\u00FDchov\u011B, vzd\u011Bl\u00E1v\u00E1n\u00ED a osv\u011Bt\u011B."),
                React.createElement("p", null, "Obsa\u017Een\u00E1 data vych\u00E1zej\u00ED z multidisciplin\u00E1rn\u00EDho v\u00FDzkumu sucha a nakl\u00E1d\u00E1n\u00ED s vodou ve vybran\u00FDch oblastech na Ji\u017En\u00ED Morav\u011B, kter\u00FD kombinoval soci\u00E1ln\u00ED a p\u0159\u00EDrodn\u00ED v\u011Bdy (antropologii, sociologii, krajinnou ekologii, geografii, bioklimatologii,) a jeho\u017E c\u00EDlem bylo porozum\u011Bt tomu, jak \u010Desk\u00E1 spole\u010Dnost reaguje na prom\u011Bny p\u0159\u00EDrodn\u00EDho prost\u0159ed\u00ED v d\u016Fsledku klimatick\u00E9 zm\u011Bny. V\u00FDstupy projektu slou\u017E\u00ED ke vzd\u011Bl\u00E1v\u00E1n\u00ED a osv\u011Bt\u011B, t\u00FDkaj\u00EDc\u00ED se problematiky sucha v \u010Cesk\u00E9 republice; byly rovn\u011B\u017E vyu\u017Eity k p\u0159\u00EDprav\u011B participativn\u00EDch n\u00E1stroj\u016F pl\u00E1nov\u00E1n\u00ED krajiny, kter\u00E9 maj\u00ED v\u00E9st k posilov\u00E1n\u00ED motivace ob\u010Dan\u016F a d\u016Fle\u017Eit\u00FDch stakeholder\u016F k udr\u017Eiteln\u00E9mu hospoda\u0159en\u00ED s vodou v dom\u00E1cnostech i v krajin\u011B."),
                React.createElement("p", null, "Vzd\u011Bl\u00E1vac\u00ED webov\u00E1 str\u00E1nka prov\u00E1z\u00ED p\u0159\u00EDb\u011Bhy o suchu a vod\u011B na dvou \u00FArovn\u00EDch:"),
                React.createElement("ul", null,
                    React.createElement("li", null,
                        React.createElement("strong", null, "Mapa:"),
                        " informativn\u00ED \u00FArove\u0148, ur\u010Den\u00E1 pro \u0161irokou ve\u0159ejnost, p\u0159edstavuj\u00EDc\u00ED srozumitelnou formou data ze z\u00E1kladn\u00EDho v\u00FDzkumu;"),
                    React.createElement("li", null,
                        React.createElement("strong", null, "P\u0159\u00EDb\u011Bh:"),
                        " \u00FArove\u0148 specificky zam\u011B\u0159en\u00E1 na environment\u00E1ln\u00ED vzd\u011Bl\u00E1v\u00E1n\u00ED d\u011Bt\u00ED a ml\u00E1de\u017Ee ve v\u011Bku 10\u201315 let, nab\u00EDzej\u00EDc\u00ED mo\u017Enost prozkoum\u00E1vat toto t\u00E9ma z\u00E1bavnou a interaktivn\u00ED formou.")),
                React.createElement("p", null,
                    React.createElement("strong", null, "auto\u0159i webu"),
                    React.createElement("br", null),
                    "design: ",
                    React.createElement("strong", null, "Maty\u00E1\u0161 Trnka"),
                    React.createElement("br", null),
                    "sc\u00E9n\u00E1\u0159: ",
                    React.createElement("strong", null, "Hynek Troj\u00E1nek"),
                    React.createElement("br", null),
                    "hlas kapky: ",
                    React.createElement("strong", null, "Halka T\u0159e\u0161\u0148\u00E1kov\u00E1"),
                    React.createElement("br", null),
                    "z\u00E1znam hlasu: ",
                    React.createElement("strong", null, "Sleepwalker, s. r. o."),
                    React.createElement("br", null),
                    "hudba:",
                    React.createElement("br", null),
                    "Discovery, Will Bedford, 2018",
                    React.createElement("br", null),
                    "Tibetan Temple, Michal Dvoracek, Petr Korinek, 2021",
                    React.createElement("br", null),
                    "HOMEMADE, Claire Leona Batchelor, 2015",
                    React.createElement("br", null),
                    "Exotic Journey C, Alex Deeping, 2020",
                    React.createElement("br", null)),
                React.createElement("p", null, "\u00A9 2022"),
                React.createElement("p", null,
                    React.createElement("strong", null, "auto\u0159i a autorky obsahu"),
                    React.createElement("br", null),
                    "Mgr. et. Mgr, Mark\u00E9ta Zandlov\u00E1, Ph.D., hlavn\u00ED \u0159e\u0161itelka projektu, FHS UK",
                    React.createElement("br", null),
                    "Mgr. Kate\u0159ina Lu\u010Dan, expertka tvorby EVVO",
                    React.createElement("br", null),
                    React.createElement("br", null),
                    "Mgr. Monika Bl\u00E1hov\u00E1, CzechGlobe, v.v.i.",
                    React.createElement("br", null),
                    "Doc. Jakub Grygar, Ph.D., FSV UK",
                    React.createElement("br", null),
                    "Mgr. Marek Havl\u00ED\u010Dek Ph.D., VUKOZ v.v.i.",
                    React.createElement("br", null),
                    "Mgr. Lucie Kudl\u00E1\u010Dkov\u00E1, CzechGlobe, v.v.i.",
                    React.createElement("br", null),
                    "Mgr. Hana Skokanov\u00E1 Ph.D., VUKOZ v.v.i.",
                    React.createElement("br", null),
                    "Mgr. Kate\u0159ina \u0160imkov\u00E1, FHS UK",
                    React.createElement("br", null),
                    "prof. Mgr. Ing. Miroslav Trnka Ph.D., CzechGlobe, v.v.i.",
                    React.createElement("br", null),
                    "Mgr. Vojt\u011Bch Pelik\u00E1n, Ph.D., FSS MUNI",
                    React.createElement("br", null),
                    "Mgr. Ane\u017Eka Pelik\u00E1nov\u00E1",
                    React.createElement("br", null),
                    "Ing. Pavla Pokorn\u00E1, AOPK \u010CR"),
                React.createElement("p", null,
                    React.createElement("strong", null, "auto\u0159i fotografi\u00ED"),
                    React.createElement("br", null),
                    "Hana Skokanov\u00E1, Marek Havl\u00ED\u010Dek, Jakub Grygar,",
                    " ",
                    React.createElement("a", { href: "https://www.karelsimecek.cz/", target: "_blank" }, "Karel \u0160ime\u010Dek"),
                    ", Mark\u00E9ta Zandlov\u00E1,",
                    " ",
                    React.createElement("a", { href: "https://www.radeksevera.cz/", target: "_blank" }, "Radek Severa")),
                React.createElement("p", null,
                    React.createElement("strong", null, "\u00FA\u010Dastn\u00EDci projektu"),
                    React.createElement("br", null),
                    "Univerzita Karlova, Fakulta humanitn\u00EDch studi\u00ED",
                    React.createElement("br", null),
                    "V\u00FDzkumn\u00FD \u00FAstav Silva Taroucy pro krajinu a okrasn\u00E9 zahradnictv\u00ED, v. v. i. ",
                    React.createElement("br", null),
                    "\u00DAstav v\u00FDzkumu glob\u00E1ln\u00ED zm\u011Bny AV \u010CR, v. v. i."),
                React.createElement("p", null,
                    React.createElement("a", { href: "https://fhs.cuni.cz/FHS-1.html", target: "_blank" },
                        React.createElement("img", { src: "./img/o-projektu/logo-01.png" })),
                    React.createElement("a", { href: "https://www.czechglobe.cz/cs/", target: "_blank" },
                        React.createElement("img", { src: "./img/o-projektu/logo-02.png" })),
                    React.createElement("a", { href: "https://www.vukoz.cz/", target: "_blank" },
                        React.createElement("img", { src: "./img/o-projektu/logo-03.png" }))),
                React.createElement("p", null,
                    React.createElement("strong", null, "aplika\u010Dn\u00ED garanti projektu"),
                    React.createElement("br", null),
                    React.createElement("a", { href: "https://portal.rozhlas.cz/", target: "_blank" }, "\u010Cesk\u00FD rozhlas"),
                    React.createElement("br", null),
                    React.createElement("a", { href: "https://www.spucr.cz/", target: "_blank" }, "St\u00E1tn\u00ED pozemkov\u00FD \u00FA\u0159ad"),
                    React.createElement("br", null),
                    React.createElement("a", { href: "http://www.pavucina-sev.cz/", target: "_blank" }, "S\u00ED\u0165 st\u0159edisek ekologick\u00E9 v\u00FDchovy Pavu\u010Dina, z.s."),
                    React.createElement("br", null),
                    React.createElement("a", { href: "https://www.veronica.cz/", target: "_blank" }, "ZO \u010CSOP Veronica")),
                React.createElement("p", null,
                    React.createElement("strong", null, "odborn\u00E9 konzultace"),
                    React.createElement("br", null),
                    "RNDr. Yvonna Gaillyov\u00E1, ZO \u010CSOP Veronica",
                    React.createElement("br", null),
                    "Ing. Arch. Magdalena Macekov\u00E1, Ph.D., Nadace Partnerstv\u00ED",
                    React.createElement("br", null),
                    "Ing. Jaroslav Slepi\u010Dka, Lipka - \u0161kolsk\u00E9 za\u0159\u00EDzen\u00ED pro environment\u00E1ln\u00ED vzd\u011Bl\u00E1v\u00E1n\u00ED Brno, p\u0159\u00EDsp\u011Bvkov\u00E1 organizace"),
                React.createElement("p", null, "Projekt P\u0159\u00EDb\u011Bhy sucha. Lok\u00E1ln\u00ED souvislosti extr\u00E9mn\u00EDch klimatick\u00FDch jev\u016F, jejich percepce a ochota akt\u00E9r\u016F k participaci/TL02000048, byl spolufinancov\u00E1n se st\u00E1tn\u00ED podporou Technologick\u00E9 agentury \u010CR v r\u00E1mci Programu \u00C9ta."),
                React.createElement("p", null,
                    React.createElement("img", { src: "./img/o-projektu/logo-04.png" }))))));
};
export { About };
