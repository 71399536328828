import React from "react";
function HelpGame() {
    return (React.createElement(React.Fragment, null,
        React.createElement("h1", null, "Hra"),
        React.createElement("p", null, "Um\u00EDsti kostky ze z\u00E1sobn\u00EDku na lev\u00E9 stran\u011B hern\u00ED plochy vpravo tak, aby proud vody odt\u00E9kal pouze do \u010Derven\u00FDch odtok\u016F, nikoli do \u0161ediv\u00FDch d\u011Br."),
        React.createElement("img", { style: {
                maxWidth: "280px",
            }, src: "https://firebasestorage.googleapis.com/v0/b/interactive-sucho.appspot.com/o/help%2Fgame_1.png?alt=media&token=f45a7d89-a282-4064-8feb-e793e2ea3d99" }),
        React.createElement("p", null, "Kostky nen\u00ED mo\u017En\u00E9 d\u00E1vat p\u0159es sebe. Tr\u00E1va na kostk\u00E1ch zadr\u017Euje vodu, p\u0159es p\u00EDsek voda te\u010De. Kostky je mo\u017En\u00E9 ot\u00E1\u010Det mezern\u00EDkem po jejich zvednut\u00ED my\u0161\u00ED."),
        React.createElement("img", { style: {
                maxWidth: "100px",
            }, src: "https://firebasestorage.googleapis.com/v0/b/interactive-sucho.appspot.com/o/help%2Fgame_2.png?alt=media&token=7db28f8c-6ac9-4265-89f3-7197eb052bcb" }),
        React.createElement("p", null, "Po \u00FAsp\u011B\u0161n\u00E9m vy\u0159e\u0161en\u00ED puzzle z\u00EDsk\u00E1\u0161 sb\u011Bratelskou kartu (bonusov\u00E1 m\u00EDsta) nebo schov\u00E1\u0161 jednu z l\u00E9taj\u00EDc\u00EDch kapek do z\u00E1sobn\u00EDku Budoucnosti (hlavn\u00ED lokality s p\u0159\u00EDb\u011Bhem).")));
}
export default HelpGame;
