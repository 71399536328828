import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled from "@emotion/styled";
import { getFirestore, getDoc, doc } from "firebase/firestore";
import { SlateViewer } from "./Slate/SlateViewer";
import { CARDS_COLLECTION } from "../const";
import { fadeInAnimation, moveInAnimation } from "../utils/Styles";
import { useCardContext } from "../context/CardContext";
import { Button, Modal, Paper } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { useGalleryContext } from "../context/GalleryContext";
import { SIMPLE_SPOTS } from "../utils/SimpleSpots";
const CardModal = styled(Modal) `
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const CardContent = styled(Paper) `
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 40px;
  padding: 32px;
  height: 600px;
  width: 360px;

  border-color: ${({ spot }) => (spot === 0 ? "#e1762f" : "#22bf31")};
  background-color: ${({ spot }) => (spot === 0 ? "#ffc9a6" : "#b5eab4")};

  ${moveInAnimation}
`;
const TitleWrap = styled.div `
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  ${fadeInAnimation}
`;
const CardTitle = styled.h1 `
  flex: 1;
`;
const IconImage = styled.img `
  height: 44px;
  margin-left: 8px;
`;
const CardImageWrap = styled.div `
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 8px;
  min-height: 150px;
  ${fadeInAnimation}
`;
const CardImage = styled.img `
  max-height: 250px;
  max-width: 100%;
`;
const CardImageIco = styled.img `
  position: absolute;
  right: 8px;
  bottom: 8px;
`;
const SlateWrap = styled.div `
  flex: 1;
  overflow-y: auto;
  ${fadeInAnimation}
`;
const CloseButtonWrap = styled.div `
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 16px;
  ${fadeInAnimation}
`;
const CloseButton = styled(Button) `
  width: 30%;
`;
function Card() {
    const [title, setTitle] = useState(null);
    const [images, setImages] = useState(null);
    const [content, setContent] = useState(null);
    const { cardId, setCardId } = useCardContext();
    const { setGalleryImages } = useGalleryContext();
    const isSpotCard = useMemo(() => {
        return SIMPLE_SPOTS.find((spot) => spot.card === cardId) != null;
    }, [cardId]);
    useEffect(() => {
        if (cardId !== null) {
            resetData();
            // Init data load
            const docRef = doc(getFirestore(), CARDS_COLLECTION, cardId);
            getDoc(docRef)
                .then((doc) => {
                if (doc.exists()) {
                    const docData = doc.data();
                    if (docData) {
                        setTitle(docData.title);
                        setImages(docData.images);
                        setContent(JSON.parse(docData.content));
                    }
                }
                else {
                    console.warn("No such document!");
                }
            })
                .catch((error) => {
                console.warn("Error getting document:", error);
            });
        }
    }, [cardId]);
    const resetData = () => {
        setTitle(null);
        setImages(null);
        setContent(null);
    };
    const handleClose = useCallback(() => {
        resetData();
        setCardId(null);
    }, [setCardId]);
    const openGallery = useCallback(() => {
        if (images && images.length > 0) {
            setGalleryImages(images);
        }
    }, [images, setGalleryImages]);
    return (React.createElement(CardModal, { open: cardId !== null, onClose: handleClose },
        React.createElement(CardContent, { spot: isSpotCard ? 0 : 1, elevation: 6 },
            React.createElement(TitleWrap, null,
                React.createElement(CardTitle, null, title ?? ""),
                React.createElement(IconImage, { src: `./img/card-ico-${isSpotCard ? "spot" : "comics"}.png`, alt: "" })),
            images && images.length > 0 && (React.createElement(CardImageWrap, { onClick: openGallery },
                React.createElement(CardImage, { src: images[0], alt: "" }),
                React.createElement(CardImageIco, { src: "./img/gallery.png", alt: "" }))),
            content && (React.createElement(SlateWrap, null,
                React.createElement(SlateViewer, { content: content }))),
            React.createElement(CloseButtonWrap, null,
                React.createElement(CloseButton, { size: "small", variant: "contained", color: "primary", disableElevation: true, onClick: handleClose },
                    React.createElement(CheckIcon, { fontSize: "large" }))))));
}
export default Card;
