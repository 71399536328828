import React, { useEffect, useState } from "react";
import axios from "axios";
import { Network } from "../../../utils/Network";
import styled from "@emotion/styled";
import { fadeInAnimation, FONT_STYLE } from "../../../utils/Styles";
import { Button, Paper } from "@mui/material";
const Container = styled.div `
  ${fadeInAnimation}
`;
const Item = styled(Paper) `
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px;
  margin: 12px 8px 0 0;
`;
const ItemTitle = styled.div `
  ${FONT_STYLE.SEMIBOLD}
  margin-bottom: 8px;
`;
const ItemDescription = styled.div `
  margin-bottom: 8px;
`;
function Download() {
    const [items, setItems] = useState([]);
    useEffect(() => {
        axios
            .get(Network.getStaticDataUrl("ke_stazeni"))
            .then((response) => {
            if (response.data) {
                const values = response.data.values;
                values.shift();
                setItems(values
                    .map((item) => {
                    return {
                        title: item[0],
                        desc: item[1],
                        url: item[2],
                    };
                })
                    .sort((a, b) => a.title.localeCompare(b.title)));
            }
        })
            .catch(function (error) {
            console.warn("Data load error:", error);
        });
    }, []);
    if (items.length === 0) {
        return null;
    }
    return (React.createElement(Container, null, items.map((item, i) => {
        return (React.createElement(Item, { elevation: 0, key: `${i}-${item.title}` },
            React.createElement(ItemTitle, null, item.title),
            item.desc && React.createElement(ItemDescription, null, item.desc),
            item.url && (React.createElement(Button, { href: item.url, download: true, target: "_blank", rel: "noreferrer", size: "small", variant: "contained", color: "primary", disableElevation: true }, "St\u00E1hnout"))));
    })));
}
export default Download;
